<template>
  <mdb-modal
    :show="selectedTask !== null"
    @show="openModal"
    @close="close"
    @shown="$emit('shown')"
    info
    size="lg"
  >
    <mdb-modal-header>
      <mdb-modal-title
        >Details of the task</mdb-modal-title
      >
    </mdb-modal-header>
    <mdb-modal-body v-if="selectedTask !== null">
      <!--<mdb-row
        v-if="selectedTask.isassigned"
        class="mb-3"
      >
        <mdb-col>
          <mdb-btn
            color="danger"
            v-on:click="confirmAbandonTask"
            size="sm"
            class="float-right"
            >Liberate this task</mdb-btn
          >
        </mdb-col>
      </mdb-row>-->
      <mdb-tbl sm>
        <mdb-tbl-body>
          <tr>
            <td>ID</td>
            <td>{{ selectedTask.taskid }}</td>
          </tr>
          <tr>
            <td>Type</td>
            <td v-if="!editable">{{ selectedTask.type }}</td>
            <td v-else>
              <select name="taskType" id="taskType" v-model="code" class="browser-default custom-select">
                <option v-for="(taskType, index) in taskTypes" :key="index" :value="taskType.code">{{ taskType.code }}</option>
              </select>
            </td>
          </tr>
          <tr>
            <td>Name</td>
            <td v-if="!editable">{{ selectedTask.name }}</td>
            <td v-else>
              <mdb-input label="Name" v-model="name" />
            </td>
          </tr>
          <tr>
            <td>Skills</td>
            <td v-if="!editable">
              {{ selectedTask.skills }}
            </td>
            <td v-else>
              <mdb-select v-model="taskSkills" label="Skills" :multiple="true" :taggable="true" :visibleOptions=10 />
            </td>
          </tr>
          <tr>
            <td>Delivery Content</td>
            <td>
              <div class="mb-2">
                <delivery-content-viewer
                  :deliverycontent="deliverycontent"
                />
              </div>
              <hr />
              <mdb-input
                v-if="!selectedTask.tasktypeinfos || !selectedTask.tasktypeinfos.deliverables"
                outline
                type="textarea"
                :rows="5"
                v-model="deliverycontent"
                placeholder="Insert modified text"
              />
              <div v-else>
              <div
                v-for="(deliverable, index) in selectedTask.tasktypeinfos
                  .deliverables"
                :key="index"
              >
                <delivery-content-uploader
                  @change="setDeliveryWip(index, $event)"
                  :name="deliverable.displayname"
                  :task-id="selectedTask.taskid"
                  :deliverable-index="index"
                  :deliverable="deliverable"
                  :retainFilename="selectedTask?.type?.includes('UPLOAD_FILE')"
                  :resumable="true"
                  :optional="deliverable.optional"
                />
              </div>
            </div>
            </td>
          </tr>
          <tr>
            <td>Remuneration Units</td>
            <td v-if="!editable">
              {{ selectedTask.remununitnb }}
            </td>
            <td v-else>
              <select name="remun_unit" id="remun_unit" class="browser-default custom-select" v-model="remununit">
                <option value="SEC">Seconds</option>
                <option value="MIN">Minutes</option>
                <option value="HOU">Hours</option>
                <option value="DAY">Days</option>
                <option value="WEE">Weeks</option>
              </select>
            </td>
          </tr>
          <tr>
            <td>Remunerated Time Units</td>
            <td v-if="!editable">
              {{ selectedTask.remununitnb }}
            </td>
            <td v-else>
              <mdb-input type="number" label="Units" :min="0" v-model="remununitnb" />
            </td>
          </tr>
          <tr>
            <td>Remuneration Rate</td>
            <td v-if="!editable">{{ selectedTask.remununitrate }}</td>
            <td v-else>
              <mdb-input type="number" label="Rate" :min="0" v-model="remununitrate" />
            </td>
          </tr>
        </mdb-tbl-body>
      </mdb-tbl>
      <mdb-row v-if="instructions !== null">
        <mdb-col>
          <hr />
          <h5>Instructions</h5>
          <hr />
          <div v-if="!editable"
            v-html="
              nl2br(instructions)
            "
          ></div>
          <div v-else>
            <mdb-input outline type="textarea" :rows="5" v-model="instructions" />
          </div>
        </mdb-col>
      </mdb-row>
      <div
        v-if="
          selectedTask.isassigned &&
          selectedTask.needfilestocomplete &&
          takeable
        "
      >
        <h2>Delivery of the task</h2>
        on met des input file pour pousser les
        fichiers terminé
      </div>
    </mdb-modal-body>
    <mdb-modal-footer
      v-if="selectedTask !== null && takeable"
    >
      <div v-if="!selectedTask.isassigned">
        By accepting to work on the following job,
        I confirm that I will never share,
        divulge, copy, transfer or use this
        content for any other means than those I
        was mandated to do. I accept to never disclose any
        information contained in this or any other
        task I work with.
        <br />
      </div>
      <div v-else>
      </div>
    </mdb-modal-footer>
    <mdb-modal-footer v-else-if="editable">
      <mdb-btn color="success" @click="saveTask">SAVE</mdb-btn>
    </mdb-modal-footer>
  </mdb-modal>
</template>

<script>
import {
  mdbBtn,
  mdbCol,
  mdbInput,
  mdbModal,
  mdbModalBody,
  mdbModalFooter,
  mdbModalHeader,
  mdbModalTitle,
  mdbRow,
  mdbTbl,
  mdbTblBody,
  mdbSelect,
} from 'mdbvue';
import Apicall from '@/libs/Apicall';
import DeliveryContentUploader from '@/components/DeliveryContentUploader.vue';
import DeliveryContentViewer from '@/components/DeliveryContentViewer.vue';

export default {
  components: {
    DeliveryContentUploader,
    DeliveryContentViewer,
    mdbBtn,
    mdbCol,
    mdbInput,
    mdbModal,
    mdbModalBody,
    mdbModalFooter,
    mdbModalHeader,
    mdbModalTitle,
    mdbRow,
    mdbTbl,
    mdbTblBody,
    mdbSelect,
  },

  props: {
    selectedTask: null,
    editable: {
      required: false,
      type: Boolean,
      default: false,
    },
    takeable: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      taskTypes: [],
      code: '',
      name: '',
      skills: '',
      deliverycontent: '',
      instructions: '',
      remununitnb: 0,
      remununit: 'MIN',
      remununitrate: 0,
      options: [
        { value: 'A', text: 'A' },
        { value: 'B', text: 'B' },
        { value: 'C', text: 'C' },
        { value: 'D', text: 'D' },
      ],
      taskSkills: [],
      skillList: [],
    };
  },

  watch: {
    taskSkills() {
      this.getSkillschanged();
    },
    selectedTask(newVal) {
      if (newVal === null) {
        this.taskSkills = [];
        this.getSkills();
      }
    },
  },

  mounted() {
    this.getTaskTypes();
    this.getSkills();
  },

  methods: {
    close() {
      this.$emit('close');
    },

    nl2br(str) {
      if (str) {
        return str.replace(
          /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
          '$1<br />$2',
        );
      }
      return '';
    },

    getTaskTypes() {
      const apiCall = new Apicall();

      apiCall.call('GET', 'api/task/get-types', null)
        .then((response) => {
          if (response.status === 'OK') {
            this.taskTypes = response.data.tasktypes;
          }
        });
    },

    getSkills() {
      const apiCall = new Apicall();
      apiCall.call('GET', 'api/task/get-skills', null)
      .then((response) => {
        if (response.status === 'OK') {
          this.skillList = response.data.taskSkills;
          this.reloadSkillsOfSelectedTask();
        }
      });
    },

    openModal() {
      this.code = this.selectedTask.type;
      this.name = this.selectedTask.name;
      this.skills = this.selectedTask.skills;
      this.remununitnb = this.selectedTask.remununitnb;
      this.remununitrate = this.selectedTask.remununitrate;
      this.remununit = this.selectedTask.remununit;
      this.instructions = this.selectedTask.instructions !== null ? this.selectedTask.instructions : '';
      this.deliverycontent = this.selectedTask.deliverycontent !== null ? this.selectedTask.deliverycontent : '';
      this.reloadSkillsOfSelectedTask();
    },

    saveTask() {
      this.$emit('save', {
        type: this.code,
        name: this.name,
        skills: this.skills,
        remununitnb: this.remununitnb,
        remununit: this.remununit,
        remununitrate: this.remununitrate,
        instructions: this.instructions !== '' ? this.instructions : null,
        deliverycontent: this.deliverycontent !== '' ? this.deliverycontent : null,
      });
    },

    reloadSkillsOfSelectedTask() {
      let currentTaskSkill = [];
      if (this.selectedTask) {
        currentTaskSkill = this.selectedTask.skills !== null ? this.selectedTask.skills.split(' ') : [];
      }
      this.taskSkills = Object.keys(this.skillList).map((key) => {
        const element = this.skillList[key];
        const found = currentTaskSkill.find((skill) => skill.toUpperCase() === element.code.toUpperCase());
        let result = null;
        if (found) {
          result = { value: element.code, text: element.code, selected: true };
        } else {
          result = { value: element.code, text: element.code, selected: false };
        }
        return result;
      });
    },
    setDeliveryWip(index, fileinfo) {
      if (!(this.selectedTask.deliverycontent instanceof Array)) {
        this.selectedTask.deliverycontent = [];
      }

      this.selectedTask.deliverycontent[fileinfo.deliverableindex] = this.selectedTask.tasktypeinfos.deliverables[
          fileinfo.deliverableindex
        ];
      this.selectedTask.deliverycontent[
        fileinfo.deliverableindex
      ].filename = fileinfo.filename;
      this.selectedTask.deliverycontent[
        fileinfo.deliverableindex
      ].size = fileinfo.filesize;
      this.selectedTask.deliverycontent[
        fileinfo.deliverableindex
      ].filename = fileinfo.filename;

      if (fileinfo.originalFilename) {
        this.selectedTask.deliverycontent[
          fileinfo.deliverableindex
        ].originalFilename = fileinfo.originalFilename;
      }

      const apiCall = new Apicall();

      apiCall
        .call('POST', 'api/task/save-delivery-content', {
          taskid: this.selectedTask.taskid,
          taskdeliverycontent: this.selectedTask.deliverycontent,
          deliverableindex: fileinfo.deliverableindex,
          fileIsZip: fileinfo.fileIsZip,
        })
        .then((response) => {
          if (response.status === 'OK') {
            this.selectedTask = response.data;
          } else {
            this.error = response.msg;
          }
        })
        .finally(() => {
          this.loadingTaskDetails = false;
        });
    },
    getSkillschanged() {
      this.skills = '';
      Object.keys(this.taskSkills).forEach((key) => {
        const element = this.taskSkills[key];
        if (element.selected === true) {
          if (this.skills === '') {
            this.skills = this.skills.concat(element.value);
          } else {
            this.skills = this.skills.concat(',', element.value);
          }
        }
      });
    },
  },
};
</script>

<style>
</style>
