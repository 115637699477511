<template>
  <mdb-container fluid>
    <Header></Header>
    <section class="mt-3">
      <employee-task-history-panel :employee-id="$store.state.id" :include-employee-details="false" />
    </section>
    <mdb-modal :show="showtaskinfos" @close="showtaskinfos = false" info size="lg">
      <mdb-modal-header>
        <mdb-modal-title>Details of the task</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row v-if="tasktodisplayinfos.isassigned" class="mb-3">
          <mdb-col>
            <mdb-btn color="danger" v-on:click="confirmAbandonTask" size="sm" class="float-right">Liberate this task</mdb-btn>
          </mdb-col>
        </mdb-row>
        <mdb-tbl sm>
          <mdb-tbl-body>
            <tr>
              <td>ID</td>
              <td>{{tasktodisplayinfos.taskid}}</td>
            </tr>
            <tr>
              <td>Type</td>
              <td>{{tasktodisplayinfos.type}}</td>
            </tr>
            <tr>
              <td>Name</td>
              <td>{{tasktodisplayinfos.name}}</td>
            </tr>
            <tr>
              <td>Skills</td>
              <td>{{tasktodisplayinfos.skills}}</td>
            </tr>
            <tr>
              <td>Payment</td>
              <td>{{tasktodisplayinfos.payment}} US$</td>
            </tr>
            <tr>
              <td>Deadline</td>
              <td>{{tasktodisplayinfos.deadline}} EST</td>
            </tr>
          </mdb-tbl-body>
        </mdb-tbl>
        <mdb-row v-if="tasktodisplayinfos.instructions">
          <mdb-col>
            <hr />
            <h5>Instructions</h5>
            <hr />
            <div v-html="nl2br(tasktodisplayinfos.instructions)" />
          </mdb-col>
        </mdb-row>
        <mdb-row v-if="tasktodisplayinfos.isassigned">
          <mdb-col>
            <hr />
            <h5>Delivery of the task</h5>
            <hr />
            <textarea v-model="tasktodisplayinfos.deliverycontent" placeholder="Insert modified text" />
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
    </mdb-modal>
  </mdb-container>
</template>

<script>
import {
  mdbContainer,
    mdbRow,
    mdbCol,
    mdbCard,
    mdbCardHeader,
    mdbCardBody,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbBtn,
} from 'mdbvue';
import Header from '@/components/Header.vue';
import EmployeeTaskHistoryPanel from '@/components/EmployeeTaskHistoryPanel.vue';
import Apicall from '@/libs/Apicall';

export default {
  name: 'userHistory',
  components: {
    EmployeeTaskHistoryPanel,
    Header,
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbBtn,
  },
  data() {
    return {
      infos: null,
      error: null,
      taskHistory: null,
      showtaskinfos: false,
      tasktodisplayinfos: {},
      totalpaymentdue: 0,
    };
  },
  mounted() {
    this.loadTaskHistory();
    this.loadTotalPaymentDue();
  },
  methods: {
    loadTaskHistory() {
        const self = this;
        const api = new Apicall();
        self.error = '';
        api.call('GET', 'api/task/my-task-history').then((response) => {
          if (response.status === 'OK') {
            self.taskHistory = response.data;
          } else {
            self.error = response.msg;
          }
        });
    },
    loadTotalPaymentDue() {
        const self = this;
        const api = new Apicall();
        self.error = '';
        api.call('GET', 'api/task/my-total-payment-due').then((response) => {
          if (response.status === 'OK') {
            self.totalpaymentdue = response.data;
          } else {
            self.error = response.msg;
          }
        });
    },
    async showTaskTodoInfos(_taskid) {
        const self = this;
        const api = new Apicall();
        self.error = '';
        self.showtaskinfos = false;
        await this.updateTaskDeliveryContent(_taskid);
        api.call('POST', 'api/task/infos', { taskid: _taskid }).then((response) => {
          if (response.status === 'OK') {
            self.showtaskinfos = true;
            self.tasktodisplayinfos = response.data;
          } else {
            self.error = response.msg;
          }
        });
      },
      async updateTaskDeliveryContent(_taskid) {
        const self = this;
        const api = new Apicall();
        await api
          .call('POST', 'api/task/check-task-delivery-content-has-valid-zip', { taskId: _taskid })
          .then((response) => {
            if (response.status !== 'OK') {
              self.error = response.msg;
            }
          });
      },
      confirmAbandonTask() {
        if (window.confirm('Are you sure you want to abandon this task?')) {
          const self = this;
          const api = new Apicall();
          api.call('POST', 'api/task/abandon-task', { taskid: this.tasktodisplayinfos.taskid }).then((response) => {
            self.showtaskinfos = false;
            if (response.status === 'OK') {
              self.loadAvailableTasks();
              self.loadMyAssignedTasks();
            } else {
              self.error = response.msg;
            }
          });
        }
      },
      nl2br(str) {
        if (str) {
          return str.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br />$2');
        }
        return '';
      },
  },
};
</script>
