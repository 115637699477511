export const formatBytesHumanReadable = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    // eslint-disable-next-line prefer-template, no-restricted-properties
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  export default class Uploader {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    constructor(file) {
      this.file = file;
      this.chunkSize = 8 * 1024 * 1024;
      this.sessionUrl = '';
      this.backendResponse = null;
      this.currentXHR = null;
      this.progress = 0;
      this.resumable = true;
      // eslint-disable-next-line no-underscore-dangle
      this._onProgress = null;
      // eslint-disable-next-line no-underscore-dangle
      this._onSuccess = null;
      // eslint-disable-next-line no-underscore-dangle
      this._onError = null;
    }

      startUpload = async (projectDetails, apicall) => {
        try {
          // eslint-disable-next-line no-param-reassign
          const uploadLinkResponse = await this.getUploadLink(projectDetails, apicall);
          if (uploadLinkResponse && uploadLinkResponse.url) {
            this.getSessionUrl(uploadLinkResponse.url).then((sessionUrl) => {
              this.sessionUrl = sessionUrl;
              this.backendResponse = uploadLinkResponse;
              this.uploadChunk(0);
            });
          }
        } catch (error) {
          // eslint-disable-next-line no-underscore-dangle
          if (this._onError) {
            // eslint-disable-next-line no-underscore-dangle
            this._onError(error);
          }
        }
      }

      getSessionUrl = (urlforupload) => new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', urlforupload, true);
        xhr.setRequestHeader('X-Goog-Resumable', 'start');
        xhr.setRequestHeader('Content-Type', this.file.type);
        // xhr.setRequestHeader("Origin", '');
        // xhr.setRequestHeader('Access-Control-Allow-Origin', 'http://127.0.0.1:8080');
        console.log(xhr);
        xhr.onload = () => {
          if (xhr.status >= 200 && xhr.status <= 300 && xhr.getResponseHeader('Location')) {
            resolve(xhr.getResponseHeader('Location'));
          } else {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject('error.network.finish');
          }
        };
        // eslint-disable-next-line prefer-promise-reject-errors
        xhr.onerror = () => reject('error.network.finish');
        xhr.send();
      })

      cancel = () => {
        // eslint-disable-next-line no-unused-expressions
        this.currentXHR?.abort();
      }

      set onProgress(handler) {
        // eslint-disable-next-line no-underscore-dangle
        this._onProgress = handler;
      }

      set onSuccess(handler) {
        // eslint-disable-next-line no-underscore-dangle
        this._onSuccess = handler;
      }

      set onError(handler) {
        // eslint-disable-next-line no-underscore-dangle
        this._onError = handler;
      }

      getUploadLink = async (projectDetails, apicall) => {
        const response = await apicall.call(
            'POST',
            'api/task/get-delivery-content-upload-link',
            projectDetails,
        );
        return response.data;
      }

      uploadChunk = (fromByte) => {
        const toByte = Math.min(fromByte + this.chunkSize, this.file.size);
        const chunk = this.file.slice(fromByte, toByte);
        const xhr = new XMLHttpRequest();

        xhr.upload.onprogress = (event) => {
          // eslint-disable-next-line no-underscore-dangle
          if (event.lengthComputable && this._onProgress) {
            const progress = Math.round((((event.loaded + fromByte) * 100) / this.file.size) * 1000) / 1000;

            // eslint-disable-next-line no-underscore-dangle
            this._onProgress(progress);
          }
        };

        xhr.onload = () => {
          if (xhr.status === 308) {
            const range = xhr.getResponseHeader('Range');
            const bytesReceived = range ? parseInt(range.split('-')[1], 10) + 1 : toByte;

            this.uploadChunk(bytesReceived);
          } else if (xhr.status >= 200 && xhr.status < 300) {
            // eslint-disable-next-line no-underscore-dangle
            if (this._onSuccess) {
              // eslint-disable-next-line no-underscore-dangle
              this._onSuccess();
            }
          } else {
            this.handleUploadError(fromByte);
          }
        };

        xhr.onerror = () => {
          this.handleUploadError(fromByte);
        };

        xhr.open('PUT', this.sessionUrl, true);
        xhr.setRequestHeader('Content-Type', this.file.type);
        xhr.setRequestHeader('Content-Range', `bytes ${fromByte}-${toByte - 1}/${this.file.size}`);
        xhr.send(chunk);

        this.currentXHR = xhr;
      }

      handleUploadError = (fromByte) => {
        setTimeout(() => {
          this.uploadChunk(fromByte);
        }, 2000);
      }

      generateInternalFilename = (originalFilename) => {
        const regexExtension = /(?:\.([^.]+))?$/;
        const extension = regexExtension.exec(originalFilename)[1];
        const randomPart = Math.random().toString(36).substring(2, 10);

        // eslint-disable-next-line prefer-template
        return new Date().getTime() + randomPart + '.' + extension;
      }

      computeProgressAndEmit = (event, fromByte) => {
        const current = fromByte + event.loaded;
        const total = this.file.size;

        const progress = {
          percent: Number(((current / total) * 100).toFixed(1)),
          size: {
            current,
            total,
          },
          humanReadableSize: {
            current: formatBytesHumanReadable(current),
            total: formatBytesHumanReadable(total),
          },
        };

        // eslint-disable-next-line no-underscore-dangle
        this._onProgress(this.generateInternalFilename(this.file.name), progress);
      }
  }
