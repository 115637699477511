<template>
    <mdb-card class="px-2 py-1">
        <mdb-card-title v-if="name">
            {{ name }}
            <span v-if="!optional" title="Required">*</span>
        </mdb-card-title>
        <mdb-card-body>
            <input type="file" @change="changeFile" multiple name="delivery_content" ref="fileInput" />
            <div style="color: red;" v-if="error !== null">{{ error }}</div>
            <div class="progress" v-if="percentageLoaded > 0">
                <div :class="`progress-bar ${error ? 'bg-danger' : (percentageLoaded == 100 ? 'bg-success' : 'bg-primary')}`" role="progressbar" :style="`width: ${percentageLoaded}%`" :aria-valuenow="percentageLoaded" aria-valuemin="0" aria-valuemax="100">
                    {{percentageLoaded}}%
                </div>
            </div>
            <div
              class="spinner-border text-dark"
              role="status"
              v-if="loadingTaskDetails"
            >
              <span class="sr-only">Checking file...</span>
            </div>
        </mdb-card-body>
    </mdb-card>
</template>

<script>
import Apicall from '@/libs/Apicall';
import {
    mdbCard,
    mdbCardBody,
    mdbCardTitle,
} from 'mdbvue';
import Uploader from '@/libs/Uploader';

export default {
components: {
    mdbCard,
    mdbCardBody,
    mdbCardTitle,
},
data() {
    return {
        file: null,
        error: null,
        percentageLoaded: 0,
        loadingTaskDetails: false,
    };
},
methods: {
    async changeFile(event) {
        this.error = null;
        this.percentageLoaded = 0;
        await this.uploadFile(this.$refs.fileInput.files[0]);
    },
    async uploadFile(file) {
        const apiCall = new Apicall();
        let theFileIsZip = false;
        this.error = null;

        const fileSizeInGB = file.size / (1024 * 1024 * 1024);
        const maxSizeInGB = this.deliverable.maxSize;
        const isMaxSizeValid = maxSizeInGB && !Number.isNaN(maxSizeInGB);

        if (isMaxSizeValid && fileSizeInGB > maxSizeInGB) {
            this.error = `File size (${fileSizeInGB.toFixed(2)} GB) exceeds the maximum allowed size (${maxSizeInGB} GB).`;
            return;
        }

        if (
            file.type === 'application/zip'
            || file.type === 'application/x-zip-compressed'
            || file.type === 'multipart/x-zip'
        ) {
            theFileIsZip = true;
        }

            const isVideo = file.type.startsWith('video/');

      if (isVideo) {
        const videoElement = document.createElement('video');
        videoElement.src = URL.createObjectURL(file);

        videoElement.addEventListener('loadedmetadata', () => {
          const maxDuration = parseFloat(this.deliverable.maxDuration);
          const { duration } = videoElement;

          if (!Number.isNaN(maxDuration) && duration > maxDuration) {
            this.error = `The video duration (${duration} seconds) exceeds the maximum allowed duration (${maxDuration} seconds).`;
            URL.revokeObjectURL(videoElement.src);
            return;
          }
            URL.revokeObjectURL(videoElement.src);
            this.startUploadProcess(file, apiCall, theFileIsZip);
        });
      } else {
        this.startUploadProcess(file, apiCall, theFileIsZip);
      }
    },
    async startUploadProcess(file, apiCall, theFileIsZip) {
      const uploader = new Uploader(file);

      uploader.onProgress = (progress) => {
        this.percentageLoaded = progress;
      };

      uploader.onSuccess = () => {
        this.$emit('change', {
          filename: uploader.backendResponse.filename,
          filetype: file.type,
          taskid: this.taskId,
          deliverableindex: this.deliverableIndex,
          public: uploader.backendResponse.public,
          fileIsZip: theFileIsZip,
          filesize: file.size,
          originalFilename: this.retainFilename ? file.name : null,
        });
      };

      uploader.onError = (error) => {
        this.error = error ?? 'Something went wrong during the upload';
      };

      const projectDetails = {
        filename: file.name,
        filetype: file.type,
        taskid: this.taskId,
        deliverableindex: this.deliverableIndex,
        public: this.deliverable.public !== null ? this.deliverable.public : false,
        fileSize: file.size,
        zip: theFileIsZip,
        resumable: this.resumable,
      };

      await uploader.startUpload(projectDetails, apiCall);
    },
},

props: {
    name: {
        type: String,
        require: false,
    },

    taskId: {
        type: String,
        require: true,
    },

    deliverableIndex: {
        type: Number,
        require: true,
    },

    deliverable: {
      type: Object,
      require: true,
    },
    retainFilename: {
      type: Boolean,
      default: false,
    },
    resumable: {
        type: Boolean,
        default: true,
    },
    optional: {
      type: Boolean,
      default: false,
    },
},
};
</script>

<style>

</style>
