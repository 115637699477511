<template>
    <mdb-card class="p-4">
      <mdb-alert color="danger" v-if="forgottenFields">All fields must be completed</mdb-alert>
      <form @submit="submit" @change="error = null">
        <mdb-row>
          <mdb-col class="m-auto">
            <mdb-card-title>
              <span>New task queue</span>
            </mdb-card-title>
          </mdb-col>
          <mdb-col
            class="d-flex justify-content-end"
          >
            <mdb-btn
              v-if="!submitting"
              color="success"
              type="submit"
              >SAVE</mdb-btn
            >
            <mdb-btn
              v-else
              color="elegant"
              disabled
            >
              <div
                class="spinner-border text-light"
                role="status"
              >
                <span class="sr-only"
                  >Loading...</span
                >
              </div>
            </mdb-btn>
          </mdb-col>
        </mdb-row>
        <mdb-row
          v-if="error"
          class="justify-content-around pt-3"
        >
          <mdb-col md="12">
            <mdb-alert color="danger">{{
              error
            }}</mdb-alert>
          </mdb-col>
        </mdb-row>
        <mdb-row v-if="loading || this.taskqueue === null">
          <div
            class="spinner-border text-secondary"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
        </mdb-row>
        <template v-else>
          <mdb-row>
            <mdb-col md="6" sm="12">
              <h3>Queue Details</h3>
              <mdb-input
                label="Task Queue Name"
                v-model="queueName"
                :required="true"
              />
              <mdb-input
                label="Billing Override"
                v-model="billingOverride"
                :disabled="$store.state.accesslevel !== 'FULLACCESS'"
                :required="true"
              />
              <mdb-input
                label="Stripe Invoice"
                v-model="stripeInvoice"
              />
              <mdb-row>
                <mdb-col md="6" sm="12"><p>RUSH?</p></mdb-col>
                <mdb-col md="6" sm="12"><mdb-input type="checkbox" id="chk_isrush" v-model="isRush" :checked="isRush" /></mdb-col>
              </mdb-row>
              <mdb-row>
                <mdb-col md="6" sm="12"><p>ADD TASKQUEUE NAME?</p></mdb-col>
                <mdb-col md="6" sm="12"><mdb-input type="checkbox" id="chk_needTaskqueueName" v-model="needTaskqueueName" :checked="needTaskqueueName" /></mdb-col>
              </mdb-row>
              <mdb-row>
                <mdb-col md="6" sm="12"><p>TO FOLLOW? </p></mdb-col>
                <mdb-col md="6" sm="12"><mdb-switch v-model="isToFollow" offLabel="" onLabel="" /></mdb-col>
              </mdb-row>
              <mdb-row>
                <mdb-col md="6" sm="12"><p>FIRST TASK CAN BE DISPATCHED? </p></mdb-col>
                <mdb-col md="6" sm="12"><mdb-switch v-model="canBeDispatched" offLabel="" onLabel="" /></mdb-col>
              </mdb-row>
              <div v-if="project">
                <h4>
                  From project: {{ project.name }}
                </h4>
              </div>
              <div>
                <div>
                  <h4>Project:</h4>
                  <input
                    type="text"
                    v-model="projectSearch"
                    @input="filterProjects"
                    placeholder="Search projects"
                    class="form-control mb-2"
                    @focus="showProjectDropdown = true"
                  />
                  <div
                    v-if="showProjectDropdown && filteredProjects.length > 0"
                    class="dropdown-menu show"
                  >
                    <a
                      class="dropdown-item"
                      v-for="(projectItem, index) in filteredProjects"
                      :key="index"
                      @click="selectProject(projectItem)"
                    >
                      {{ projectItem.name }} - {{ projectItem.client.email }}
                    </a>
                  </div>
                </div>
                <div v-if="duplicateProjectId" class="w-100 mt-3">
                  <h3>Link with taskqueue:</h3>
                  <mdb-input label="Search taskqueue" v-model="taskqueueLinkSearch" @change="searchTaskqueue" />
                  <mdb-tbl class="w-100" hover>
                    <mdb-tbl-head>
                        <tr>
                          <th>ID</th>
                          <th>Taskqueue Name</th>
                        </tr>
                    </mdb-tbl-head>
                    <mdb-tbl-body>
                      <tr v-for="(taskqueue) in taskqueueSearchResults" :key="taskqueue.taskqueueid" :class="`${taskqueue.checked ? 'bg-success' : ''}`" @click="selectLinkTaskqueueResult(taskqueue.taskqueueid)">
                        <td>{{taskqueue.taskqueueid}}</td>
                        <td>{{taskqueue.queuename}}</td>
                      </tr>
                    </mdb-tbl-body>
                  </mdb-tbl>
                </div>
                <div v-if="selectedLinkTaskqueueId">
                  <label for="linkTaskqueueTask">Use task</label>
                  <select
                    id="linkTaskqueueTask"
                    name="linkTaskqueueTask"
                    v-model="linkTaskqueueTask"
                    class="browser-default custom-select"
                  >
                    <option value="">Last one (default)</option>
                    <option v-for="(task) in linkTaskqueueTasks" :key="task.taskid" :value="task.taskid">{{task.name}}</option>
                  </select>
                </div>
              </div>
            </mdb-col>
            <mdb-col md="6" sm="12">
              <div class="mb-2">
                <label for="templateToApply" class="form-label">Choose a template:</label>
                <input
                  type="text"
                  v-model="templateSearch"
                  @input="filterTemplates"
                  placeholder="Search templates"
                  class="form-control mb-2"
                  @focus="showTemplateDropdown = true"
                />
                <div
                  v-if="showTemplateDropdown && filteredTemplates.length > 0"
                  class="dropdown-menu show"
                >
                  <a
                    class="dropdown-item"
                    v-for="(template, i) in filteredTemplates"
                    :key="i"
                    @click="selectTemplate(i, template)"
                  >
                    {{ template.name }}
                  </a>
                </div>
              </div>
              <div>
                <h3>
                  Tasks
                  <mdb-btn
                    color="success"
                    @click="addTask()"
                    size="sm"
                  >
                    <mdb-icon icon="plus" />
                  </mdb-btn>
                </h3>
                <mdb-list-group>
                  <mdb-list-group-item
                    v-for="(task, index) in tasks"
                    :key="index"
                  >
                    <div class="w-50">
                      <mdb-icon
                        v-if="task.iscompleted"
                        icon="check-double"
                      />
                      # {{ index + 1 }}
                      {{ task.name }} [{{
                        task.type
                      }}]
                    </div>
                    <div>
                      <mdb-btn
                        size="sm"
                        color="info"
                        @click="
                          setSelectedTask(task, index)
                        "
                      >
                        <span
                          v-if="showIndex === index"
                          class="
                            spinner-border
                            spinner-border-sm
                          "
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <mdb-icon v-else icon="eye" />
                      </mdb-btn>
                      <template
                        v-if="tasks.length > 1"
                      >
                        <mdb-btn
                          v-if="index > 0"
                          size="sm"
                          color="primary"
                          @click="
                            moveTask(index, index - 1)
                          "
                        >
                          <mdb-icon icon="arrow-up" />
                        </mdb-btn>
                        <mdb-btn
                          v-else
                          size="sm"
                          color="elegant"
                          disabled
                          ><mdb-icon icon="arrow-up"
                        /></mdb-btn>
                        <mdb-btn
                          v-if="
                            index !== tasks.length - 1
                          "
                          size="sm"
                          color="primary"
                          @click="
                            moveTask(index, index + 1)
                          "
                        >
                          <mdb-icon
                            icon="arrow-down"
                          />
                        </mdb-btn>
                        <mdb-btn
                          v-else
                          size="sm"
                          color="elegant"
                          disabled
                          ><mdb-icon
                            icon="arrow-down"
                        /></mdb-btn>
                        <mdb-btn
                          size="sm"
                          color="danger"
                          @click="deleteTask(index)"
                          ><mdb-icon icon="trash-alt"
                        /></mdb-btn>
                      </template>
                    </div>
                  </mdb-list-group-item>
                </mdb-list-group>
              </div>
            </mdb-col>
          </mdb-row>
        </template>
      </form>
      <task-modal
        :selectedTask="selectedTask"
        @close="selectedTask = null"
        :editable="true"
        :takeable="false"
        @save="saveTaskDetails"
        @shown="showIndex = null"
      />
    </mdb-card>
  </template>
  <script>
  import {
    mdbAlert,
    mdbBtn,
    mdbCard,
    mdbCardTitle,
    mdbCol,
    mdbIcon,
    mdbInput,
    mdbListGroup,
    mdbListGroupItem,
    mdbRow,
    mdbSwitch,
    mdbTbl,
    mdbTblBody,
    mdbTblHead,
  } from 'mdbvue';
  import Apicall from '@/libs/Apicall';
  import TaskModal from './TaskModal.vue';

  export default {
    components: {
      mdbAlert,
      mdbBtn,
      mdbCard,
      mdbCardTitle,
      mdbCol,
      mdbIcon,
      mdbInput,
      mdbListGroup,
      mdbListGroupItem,
      mdbRow,
      mdbTbl,
      mdbTblBody,
      mdbTblHead,
      TaskModal,
      mdbSwitch,
    },

    props: {
      errorProp: {
        required: false,
        default: null,
      },
      taskqueueId: {
        required: false,
        default: null,
      },
      submitting: {
        required: true,
        default: false,
      },
      reload: {
        required: true,
        default: false,
      },
    },

    data() {
      return {
        name: '',
        queueName: '',
        linkTaskqueueTask: '',
        error: null,
        templateIndex: null,
        taskqueueTemplates: [],
        templateSearch: '',
        projectSearch: '',
        filteredProjects: [],
        filteredTemplates: [],
        linkTaskqueueTasks: [],
        loading: false,
        canBeDispatched: true,
        stripeInvoice: '',
        taskqueue: null,
        taskqueueLinkSearch: '',
        project: null,
        tasks: [],
        billingOverride: null,
        projectList: [],
        effortRatio: null,
        showIndex: null,
        selectedTask: null,
        duplicateProjectId: null,
        forgottenFields: false,
        billingOverridenManually: false,
        isRush: false,
        isToFollow: false,
        needTaskqueueName: false,
        taskqueueSearchResults: [],
        timeoutId: null,
        showProjectDropdown: false,
        showTemplateDropdown: false,
      };
    },

    computed: {
      selectedLinkTaskqueueId() {
        const selectedLinkedTaskqueues = this.taskqueueSearchResults.filter((result) => result.checked);

        return selectedLinkedTaskqueues.length > 0 ? selectedLinkedTaskqueues.shift()?.taskqueueid : null;
      },
    },

    beforeMount() {
      this.getProjectInfos();
      this.getEffortRatio();
      this.getTaskqueueTemplates();
    },

    mounted() {
      if (!this.taskqueueId) {
        this.taskqueue = {
          taskqueueid: null,
          projectid: this.duplicateProjectId,
          stripeinvoice: '',
        };

        this.tasks = [];
      }
    },

    /* beforeDestroy() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
    }, */

    methods: {
      selectLinkTaskqueueResult(taskqueueid) {
        this.taskqueueSearchResults = this.taskqueueSearchResults.map((taskqueue) => ({ ...taskqueue, checked: taskqueueid === taskqueue.taskqueueid }));
      },
      deleteTask(index) {
        this.tasks.splice(index, 1);

        const newBilling = this.recalculateTaskEfforts();

        if (newBilling !== this.billingOverride) {
          this.billingOverride = newBilling;
        }
      },

      setSelectedTask(task, index) {
        this.selectedTask = task;

        this.showIndex = index;
      },

      getEffortRatio() {
        const apiCall = new Apicall();

        apiCall
          .call('GET', 'api/task/effort-ratio', null)
          .then((response) => {
            if (response.status === 'OK') {
              this.effortRatio = response.data.effortratio;
            } else {
              this.error = response.msg;
            }
          });
      },

      getProjectInfos() {
        const apiCall = new Apicall();

        apiCall
          .call('GET', 'api/project/get-all', null)
          .then((response) => {
            if (response.status === 'OK') {
              if (typeof response.data.projectinfos === 'object' && !Array.isArray(response.data.projectinfos)) {
              this.projectList = Object.values(response.data.projectinfos);
            } else {
              this.projectList = response.data.projectinfos || [];
            }
              this.filterProjects();
            } else {
              this.error = response.msg;
            }
          });
      },

      getTaskqueueTemplates() {
        const apiCall = new Apicall();

        apiCall
          .call('GET', 'api/task/taskqueue-templates', null)
          .then((response) => {
            if (response.status === 'OK') {
              this.taskqueueTemplates = response.data.templates;
              this.filteredTemplates = this.taskqueueTemplates;
            } else {
              this.error = response.msg;
            }
          });
      },

      getTasksForLinkTaskqueue() {
        const taskqueueId = this.taskqueueSearchResults.filter((taskqueue) => taskqueue.checked).shift()?.taskqueueid;
        if (!taskqueueId) {
          return;
        }
        const apiCall = new Apicall();

        apiCall
          .call('GET', `api/task/tasks-in-order?taskId=${taskqueueId}`, null)
          .then((response) => {
            if (response.status === 'OK') {
              this.linkTaskqueueTasks = response.data.tasks.filter((task) => !task.isspotcheck);
            } else {
              this.error = response.msg;
            }
          });
      },

      moveTask(oldIndex, newIndex) {
        const task = this.tasks[oldIndex];

        this.tasks.splice(oldIndex, 1);
        this.tasks.splice(newIndex, 0, task);
      },

      addTask() {
        const task = {
          taskid: null,
          name: 'New task',
          type: null,
          remununit: 'MIN',
          remununitnb: 0,
          remununitrate: 0.09,
          instructions: null,
          isassigned: false,
          iscompleted: false,
          needsfilestocomplete: false,
        };

        this.tasks.push(task);

        this.showIndex = null;

        const newBilling = this.recalculateTaskEfforts();

        if (newBilling !== this.billingOverride) {
          this.billingOverride = newBilling;
        }
      },

      recalculateTaskEfforts() {
        let totalBilling = 0;

        this.tasks.forEach((task) => {
          totalBilling += Math.ceil(task.remununitnb / this.effortRatio);
        });

        return totalBilling;
      },

      saveTaskDetails(newDetails) {
        if (this.selectedTask) {
          this.selectedTask.type = newDetails.type;
          this.selectedTask.instructions = newDetails.instructions;
          this.selectedTask.name = newDetails.name;
          this.selectedTask.remununit = newDetails.remununit;
          this.selectedTask.remununitrate = newDetails.remununitrate;
          this.selectedTask.remununitnb = newDetails.remununitnb;
          this.selectedTask.skills = newDetails.skills;
          this.selectedTask = null;

          const newBilling = this.recalculateTaskEfforts();

          if (newBilling !== this.billingOverride) {
            this.billingOverride = newBilling;
          }
        }
      },

      copyQueueToProject(projectid) {
        const apiCall = new Apicall();

        apiCall.call(
          'POST',
          'api/task/duplicate-queue',
          { taskqueueid: this.taskqueueId, projectid },
        ).then((response) => {
          if (response.status === 'OK') {
            this.$emit('reload', response.data.taskqueueinfos.taskqueueid);
          } else {
              this.error = response.msg;
          }
        });
      },

      submit(event) {
        event.preventDefault();
        if (!this.duplicateProjectId || this.tasks.length < 1) {
          this.forgottenFields = true;
          return;
        }
        this.forgottenFields = false;
        this.taskqueue.queuename = this.queueName;
        this.taskqueue.projectid = this.duplicateProjectId;
        this.taskqueue.stripeinvoice = this.stripeInvoice;
        this.taskqueue.isrush = this.isRush;
        this.taskqueue.istofollow = this.isToFollow;
        this.taskqueue.canbedispatched = this.canBeDispatched;
        if (this.needTaskqueueName) {
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < this.tasks.length; i++) {
            const task = this.tasks[i];
            task.name = `${task.name} ${this.taskqueue.queuename}`;
          }
        }

        if (
          this.$store.state.accesslevel === 'FULLACCESS'
          && this.billingOverride !== this.recalculateTaskEfforts()
        ) {
          this.taskqueue.billingoverride = this.billingOverride;
        }

        const selectedLinkedTaskqueues = this.taskqueueSearchResults.filter((result) => result.checked);

        this.taskqueue.link_taskqueue_id = selectedLinkedTaskqueues.length > 0 ? selectedLinkedTaskqueues.shift()?.taskqueueid : null;

        this.taskqueue.link_task_id = this.taskqueue.link_taskqueue_id && this.linkTaskqueueTask ? this.linkTaskqueueTask : null;
        this.$emit(
            'submit',
          this.taskqueue,
          this.tasks,
        );
      },

      applyTemplate(tasks) {
        this.tasks = tasks.map((task) => ({
              taskid: null,
              name: task.name,
              type: task.type,
              remununit: task.remun_unit,
              instructions: task.instructions,
              remununitnb: task.remun_unit_nb,
              remununitrate: task.remun_unit_rate,
              isassigned: false,
              iscompleted: false,
              skills: task.skills.join(' '),
              needsfilestocomplete: false,
        }));
      },
      searchTaskqueue() {
        if (this.timeoutId) {
          clearTimeout(this.timeoutId);
        }
        this.timeoutId = setTimeout(() => {
          const apiCall = new Apicall();

          apiCall.call('POST', 'api/task/search-taskqueue-within-project', {
            projectId: this.duplicateProjectId,
            searchTerm: this.taskqueueLinkSearch,
          }).then((response) => {
            console.log(response.data);
            this.taskqueueSearchResults = response.data;
          });
        }, 1000);
      },
      filterProjects() {
        const searchTerm = this.projectSearch.toLowerCase();
        this.filteredProjects = this.projectList.filter((project) => project.name.toLowerCase().includes(searchTerm)
              || project.client?.email?.toLowerCase().includes(searchTerm));
      },
      selectProject(projectItem) {
        this.projectSearch = `${projectItem.name} - ${projectItem.client.email}`;
        this.duplicateProjectId = projectItem.projectid;
        this.showProjectDropdown = false;
      },
      selectTemplate(index, template) {
        this.templateSearch = template.name;
        const correctTemplate = this.taskqueueTemplates.find((t) => t.id === template.id);
        this.applyTemplate(correctTemplate.parsedTemplate.templates);
        this.showTemplateDropdown = false;
      },
      filterTemplates() {
        const searchTerm = this.templateSearch.toLowerCase();
        this.filteredTemplates = this.taskqueueTemplates.filter((template) => template.name.toLowerCase().includes(searchTerm));
      },
    },

    watch: {
      errorProp() {
        if (this.errorProp) {
          this.error = this.errorProp;
        }
      },
      selectedLinkTaskqueueId(currentTaskqueueId) {
        if (currentTaskqueueId) {
          this.getTasksForLinkTaskqueue();
        } else {
          this.linkTaskqueueTask = '';
        }
      },
    },
  };
  </script>

  <style>
  </style>
