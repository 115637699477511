<template>
  <mdb-container fluid>
    <Header></Header>
    <Admintopbar :breadcrumb='breadcrumb' />
    <section class="mt-3">
      <form @submit="submitForm">
        <mdb-row>
          <mdb-col>
            <mdb-card>
              <mdb-card-header class="mdb-color darken-1">
                Mail details
              </mdb-card-header>
              <mdb-card-body>
                <mdb-row>
                  <mdb-col>
                    <mdb-btn color="secondary" :disabled="loadingForm" @click="() => showEmailSendModal = true">
                      Send a test
                    </mdb-btn>
                    <mdb-btn color="success" type="submit" :disabled="loadingForm">Save</mdb-btn>
                    <mdb-btn color="danger" v-if="id !== 0" @click="toggleActive" :disabled="loadingForm">
                      {{ active == '0' ? 'PUBLISH' : 'DEACTIVATE' }}
                    </mdb-btn>
                  </mdb-col>
                  <mdb-col>
                    <mdb-spinner v-if="loadingForm"></mdb-spinner>
                  </mdb-col>
                </mdb-row>
                <mdb-row>
                  <mdb-col>
                    <mdb-input
                      label="Subject"
                      name="subject"
                      type="text"
                      :readOnly="loadingForm"
                      v-model="subject"
                    />
                  </mdb-col>
                </mdb-row>
                <mdb-row>
                  <mdb-col>
                    <mdb-input
                      label="ID"
                      name="ident"
                      type="text"
                      v-model="ident"
                      :readOnly="loadingForm"
                      small="Must be unique and only contain letters, numbers and underscores"
                      @change="cleanName"
                    />
                  </mdb-col>
                  <mdb-col>
                    <mdb-input label="Minimum send date" type="date"
                      :readOnly="loadingForm"
                      v-model="minimumSendDate"/>
                  </mdb-col>
                  <mdb-col>
                    <mdb-input label="Maximum send date" type="date"
                    :readOnly="loadingForm"
                    v-model="maximumSendDate"/>
                  </mdb-col>
                </mdb-row>
                <mdb-row>
                  <mdb-col>
                    <mdb-input label="Hour from" type="time"
                    :readOnly="loadingForm"
                    v-model="fromHour"/>
                  </mdb-col>
                  <mdb-col>
                    <mdb-input label="Hour to" type="time"
                    :readOnly="loadingForm"
                    v-model="toHour"/>
                  </mdb-col>
                  <mdb-col></mdb-col>
                </mdb-row>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
        <mdb-row class="mt-2">
          <mdb-col>
            <mdb-card>
              <mdb-card-header class="bg-success text-white">
                Mail body
              </mdb-card-header>
              <mdb-card-body>
                <textarea name="textitle-input" :readOnly="loadingForm" class="w-100 min-vh-100" v-model="value"></textarea>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
          <mdb-col>
            <mdb-card>
              <mdb-card-header class="bg-success text-white">
                Mail preview
              </mdb-card-header>
              <mdb-card-body>
                <div class="w-100 min-vh-100" v-html="view"></div>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </form>
    </section>
    <mdb-modal :show="showEmailSendModal" @close="showEmailSendModal = false">
      <mdb-modal-header>Send email to user</mdb-modal-header>
      <mdb-modal-body>
        <select name="user-email" id="user-email" class="form-control" v-model="emailSendTo">
          <option :value="$store.state.email">Me</option>
          <option value="david@soustitreur.com">David Grégoire</option>
          <option value="felix@soustitreur.com">Félix</option>
          <option value="davidarmando@soustitreur.com">David Armando</option>
          <option value="samueline@soustitreur.com">Samueline</option>
          <option value="tiana@soustitreur.com">Tiana</option>
        </select>
        <mdb-btn @click="sendEmailTest">Send email</mdb-btn>
      </mdb-modal-body>
    </mdb-modal>
  </mdb-container>
</template>

<script>
import {
  mdbBtn,
  mdbCard,
  mdbCardHeader,
  mdbCardBody,
  mdbCol,
  mdbInput,
  mdbContainer,
  mdbModal,
  mdbModalBody,
  mdbModalHeader,
  mdbRow,
  mdbSpinner,
} from 'mdbvue';
import Header from '@/components/Header.vue';
import Admintopbar from '@/components/Admintopbar.vue';
import textile from 'textile-js';
import Apicall from '@/libs/Apicall';

export default {
  components: {
    Admintopbar,
    Header,
    mdbBtn,
    mdbCard,
    mdbCardHeader,
    mdbCardBody,
    mdbContainer,
    mdbCol,
    mdbInput,
    mdbModal,
    mdbModalBody,
    mdbModalHeader,
    mdbRow,
    mdbSpinner,
  },
  data() {
    return {
      id: 0,
      value: '',
      ident: '',
      subject: '',
      minimumSendDate: '',
      maximumSendDate: '',
      fromHour: '',
      toHour: '',
      loadingForm: false,
      showEmailSendModal: false,
      emailSendTo: '',
      active: '0',
      breadcrumb: [
        {
          title: 'Mailing Campaigns',
          href: '/admin/mailing-campaigns',
        },
        {
          title: 'Form',
          href: null,
        },
      ],
    };
  },
  mounted() {
    const { mailingCampaignId } = this.$route.query;

    if (!mailingCampaignId) {
      return;
    }

    document.querySelector('body').scrollTo({
      top: 0,
    });

    this.getMailingCampaign(mailingCampaignId);
  },
  computed: {
    view() {
      return textile(this.value);
    },
  },
  methods: {
    getMailingCampaign(mailingCampaignId) {
      const apiCall = new Apicall();

      this.loadingForm = true;

      apiCall.call('POST', 'api/mailing-campaign/get-campaign', { mailingCampaignId }).then((response) => {
        this.loadingForm = false;

        if (response.status === 'OK') {
          this.id = response.data.mailingCampaignId;
          this.ident = response.data.ident;
          this.subject = response.data.subject;
          this.minimumSendDate = response.data.mindatetosend;
          this.maximumSendDate = response.data.maxdatetosend;
          this.fromHour = response.data.sendbetweenhourfrom;
          this.toHour = response.data.sendbetweenhourto;
          this.value = response.data.template;
          this.active = response.data.active;
        } else {
          this.$notify.error({
            message: response.msg,
            position: 'top right',
            timeOut: 5000,
          });
        }
      });
    },
    cleanName(name) {
      this.ident = name.replaceAll(/[^\dA-z]/g, '_');
    },
    sendEmailTest() {
      const apiCall = new Apicall();

      this.showEmailSendModal = false;
      this.loadingForm = true;

      apiCall.call(
        'POST',
        'api/mailing-campaign/send-email-test',
        {
          subject: this.subject,
          emailSendTo: this.emailSendTo,
          body: this.value,
        },
      ).then((response) => {
        this.loadingForm = false;

        if (response.status === 'OK') {
          this.$notify.success({
            message: 'Mailing campaign test sent',
            position: 'top right',
            timeOut: 5000,
          });
        } else {
          this.$notify.error({
            message: response.msg,
            position: 'top right',
            timeOut: 5000,
          });
        }
      });
    },
    toggleActive() {
      const apiCall = new Apicall();

      this.loadingForm = true;

      apiCall.call('POST', 'api/mailing-campaign/toggle-active', { mailingCampaignId: this.id }).then((response) => {
        this.loadingForm = false;

        if (response.status === 'OK') {
          this.active = response.data.active;
          this.$notify.success({
            message: `Mailing campaign ${response.data.active ? 'published' : 'deactivated'}`,
            position: 'top right',
            timeOut: 5000,
          });
        } else {
          this.$notify.error({
            message: response.msg,
            position: 'top right',
            timeOut: 5000,
          });
        }
      });
    },
    submitForm(event) {
      event.preventDefault();

      const apiCall = new Apicall();

      this.loadingForm = true;

      apiCall.call(
        'POST',
        'api/mailing-campaign/save',
        {
          mailingCampaignId: this.id,
          ident: this.ident,
          subject: this.subject,
          minimumSendDate: this.minimumSendDate,
          maximumSendDate: this.maximumSendDate,
          fromHour: this.fromHour,
          toHour: this.toHour,
          body: this.value,
        },
      ).then((response) => {
        this.loadingForm = false;

        if (response.status === 'OK') {
          this.id = response.data.mailingCampaignId;
          this.$notify.success({
            message: 'Mailing campaign saved',
            position: 'top right',
            timeOut: 5000,
          });
        } else {
          this.$notify.error({
            message: response.msg,
            position: 'top right',
            timeOut: 5000,
          });
        }
      });
    },
  },
};
</script>

<style>

</style>
