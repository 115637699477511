<template>
  <div class="text-wrap" v-html="instructionView"></div>
</template>

<script>
export default {
  props: {
    instructions: {
      type: String,
      required: true,
    },
  },

  computed: {
    instructionView() {
      return this.instructions
          .replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br />$2')
          .replaceAll(
            /((http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])(?:;(\s)?filename=".*")?)/g,
            '<a class="ml-1" style="text-decoration: underline;  overflow-wrap: break-word;" target="_blank" href="$1">$1</a>',
          );
    },
  },
};
</script>

<style scoped>
</style>
