<template>
    <div>
      <div v-if="(deliverycontent instanceof Array)" class="d-flex flex-wrap justify-content-start text-wrap">
        <div v-for="(deliveryElement, index) in deliverycontent" :key="index" class="d-flex flex-column align-items-center">
          <a
            v-if="deliveryElement"
            :class="`btn btn-sm ${deliveryElement ? deliveryElement.type === 'zip' && !deliveryElement.hasOwnProperty('isValidZip') ? 'bg-gray text-dark' : 'btn-primary' : 'bg-gray text-dark'} mt-1 mx-2 d-flex align-items-center justify-content-center gap-1`"
            style="gap: 5px" :href="deliveryElement ? deliveryElement.downloadurl : '#'" target="__blank"
          >
            <mdb-icon v-if="markPublic && deliveryElement.public" icon="users"></mdb-icon>
            {{deliveryElement ? deliveryElement.displayname : 'No file yet'}}
            <div v-if="deliveryElement.type === 'zip' && !isSpotcheck">
              <div v-if="!deliveryElement.hasOwnProperty('isValidZip')">
                <div
                  class="spinner-border text-ligth"
                  style="width: 1.25rem; height: 1.25rem;"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <div v-else>
                <mdb-icon class="fa-2x" :icon="deliveryElement.isValidZip ? 'check' : 'times'" :class="deliveryElement.isValidZip ? 'green-text' : 'red-text'"/>
              </div>
            </div>
          </a>
          <div v-if="deliveryElement && deliveryElement.type === 'zip' && !isSpotcheck">
            <p v-if="!deliveryElement.hasOwnProperty('isValidZip')" class="red-text mx-1">This file is currently being reviewed, please come back later</p>
            <p v-else-if="!deliveryElement.isValidZip" class="red-text mx-1">The zip file must contain video and prproj files</p>
          </div>
        </div>
      </div>
      <div v-else-if="deliverycontent !== null">
        {{ deliverycontent }}
      </div>
      <div v-else>
        <span class="text-red">---</span>
      </div>
      <slot></slot>
    </div>
</template>

<script>

import {
    mdbIcon,
} from 'mdbvue';

export default {
    components: {
        mdbIcon,
    },
    props: {
        deliverycontent: null,
        taskType: null,
        markPublic: {
          type: Boolean,
          default: false,
        },
    },
    data() {
        return {
            isSpotcheck: false,
        };
    },
    mounted() {
        this.taskIsSpotcheck();
    },
    methods: {
        taskIsSpotcheck() {
            this.isSpotcheck = (this.taskType && (this.taskType.includes('SPOTCHECK') || this.taskType === 'VIDEO_EDITINGR' || this.taskType === 'PHOTOSHOP_EDITING_R'));
        },
    },
};
</script>

<style>

</style>
