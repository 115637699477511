<template>
  <mdb-container fluid>
    <Header></Header>
    <mdb-row v-if="error" class="justify-content-around pt-3">
      <mdb-col md="6">
        <mdb-alert color="danger">{{ error }}</mdb-alert>
      </mdb-col>
    </mdb-row>
    <mdb-row v-if="$store.state.haspaymentinfo === false" class="justify-content-around pt-3">
      <mdb-col md="6">
        <mdb-alert color="info" >Don't forget to go in your <a href="/userdetails">PlusCrew's user preferences</a> to choose a payment method</mdb-alert>
      </mdb-col>
    </mdb-row>
    <!-- <mdb-row v-if="positionInWeeklyContest">
      <mdb-col md="12" class="align-items-center mt-3">
        <mdb-alert color="info" class="mt-1">
          This week, you have delivered {{tasks}} tasks for the challenge. This places you at position {{positionInWeeklyContest}} in the global team! <br /> Reminder: 1st place: 60$ USD, 2nd place: 25$ USD and 3rd place: 15$ USD. This week’s contest finishes this Saturday {{nextSaturday}} at 23:59 New York Time!
        </mdb-alert>
      </mdb-col>
    </mdb-row> -->
    <section class="mt-3">
      <mdb-row>
        <mdb-col md="6">
          <mdb-card>
            <mdb-card-header color="orange accent-3"
              >Tasks available for you</mdb-card-header
            >
            <mdb-card-body>
              <mdb-spinner multicolor size="lg" v-if="availjobsloading" />
                <div v-if="availabletasks.length > 0 && (this.$store.state.inprobation === '1' ? assignedjobs.length < 3 : true)">
                <mdb-tbl v-if="availjobsloading === false" responsive sm>
                  <mdb-tbl-head>
                    <tr>
                      <th style="font-weight: bold">Project ID</th>
                      <th style="font-weight: bold">Task ID</th>
                      <th style="font-weight: bold">Type</th>
                      <th style="font-weight: bold">Title<br />Name</th>
                    </tr>
                  </mdb-tbl-head>
                  <mdb-tbl-body class="main">
                    <tr
                      v-for="(task, index) in availabletasks"
                      v-bind:key="index"
                      style="cursor: pointer"
                      v-on:click="showTaskTodoInfos(task.taskid)"
                    >
                      <td>{{ task.projectid }}</td>
                      <td>{{ task.taskid }}</td>
                      <td>
                        {{ task.type }}
                      </td>
                      <td>
                        {{ task.name }}
                      </td>
                    </tr>
                  </mdb-tbl-body>
                </mdb-tbl>
              </div>
              <div v-else>
                <h2>No task is available for you for the moment</h2>
              </div>
            </mdb-card-body>
          </mdb-card>
          <mdb-card class="mt-3">
            <mdb-card-header color="green accent-3"
              >Tasks available for you at Soustitreur</mdb-card-header
            >
            <mdb-card-body>
              <mdb-spinner
                multicolor
                size="lg"
                v-if="availjobssoustitreurloading"
              />
              <div v-if="assignedsoustitreurjobs.length > 0">
                <mdb-tbl
                  v-if="availjobssoustitreurloading === false"
                  responsive
                  sm
                >
                  <mdb-tbl-head>
                    <tr>
                      <th style="font-weight: bold">Type<br />Language</th>
                      <th style="font-weight: bold">Title<br />OrderNum</th>
                      <th style="font-weight: bold">Duration<br />Deadline</th>
                    </tr>
                  </mdb-tbl-head>
                  <mdb-tbl-body class="main">
                    <tr
                      v-for="(job, index) in assignedsoustitreurjobs"
                      v-bind:key="index"
                      style="cursor: pointer"
                      v-on:click="openSoustitreurJob(job.url, job.id)"
                    >
                      <td>{{ job.type }}<br />{{ job.language }}</td>
                      <td class="title-max-width">
                        {{ job.title }}
                        <span v-if="job.type === 'INAUDIBLE'">{{
                          `"${job.lines.substr(0, 30)}..."`
                        }}</span>
                        <br />
                        OrderNum: #{{ job.ordernum }}
                        <span
                          class="badge badge-info"
                          v-if="job.is_challenging === true"
                          >Challenging</span
                        >
                      </td>
                      <td>
                        {{ job.duration }}
                        <br />
                        {{ job.deadlineremaintime }}
                      </td>
                    </tr>
                  </mdb-tbl-body>
                </mdb-tbl>
              </div>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
        <mdb-col md="6">
          <mdb-card>
            <mdb-card-header color="grey accent-3">
              <div
                class="d-flex justify-content-between align-items-center"
                style="height: 2rem"
              >
                My Todo tasks
                <div
                  class="spinner-border text-dark"
                  role="status"
                  v-if="loadingTaskDetails"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </mdb-card-header>
            <mdb-card-body>
              <mdb-spinner multicolor size="lg" v-if="assignedjobsloading" />
              <div v-if="assignedjobs.length > 0">
                <mdb-tbl v-if="assignedjobsloading === false" responsive sm>
                  <mdb-tbl-head>
                    <tr>
                      <th style="font-weight: bold">ID</th>
                      <th style="font-weight: bold">Type</th>
                      <th style="font-weight: bold">Title<br />Name</th>
                    </tr>
                  </mdb-tbl-head>
                  <mdb-tbl-body class="main">
                    <tr
                      v-for="(task, index) in assignedjobs"
                      v-bind:key="index"
                      style="cursor: pointer"
                      v-on:click="showTaskTodoInfos(task.taskid)"
                    >
                      <td>{{ task.taskid }}</td>
                      <td>
                        {{ task.type }}
                      </td>
                      <td>
                        {{ task.name }}
                      </td>
                    </tr>
                  </mdb-tbl-body>
                </mdb-tbl>
              </div>
              <div
                v-if="assignedjobs.length == 0 && assignedjobsloading === false"
              >
                <h2>No task to do</h2>
              </div>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
      </mdb-row>
    </section>
    <mdb-modal
      :show="showtaskinfos"
      @close="showtaskinfos = false"
      info
      size="lg"
    >
      <mdb-modal-header>
        <mdb-modal-title> Details of the task </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row v-if="tasktodisplayinfos.isassigned && tasktodisplayinfos.employeeid == $store.state.id" class="mb-3">
          <mdb-col>
            <mdb-btn
              color="danger"
              v-on:click="confirmAbandonTask"
              size="sm"
              class="float-right"
              >Liberate this task</mdb-btn
            >
          </mdb-col>
        </mdb-row>
        <mdb-tbl sm>
          <mdb-tbl-body>
            <tr>
              <td>ID</td>
              <td>{{ tasktodisplayinfos.taskid }}</td>
            </tr>
            <tr>
              <td>Type</td>
              <td>{{ tasktodisplayinfos.type }}</td>
            </tr>
            <tr>
              <td>Name</td>
              <td>{{ tasktodisplayinfos.name }}</td>
            </tr>
            <tr>
              <td>Skills</td>
              <td>{{ tasktodisplayinfos.skills }}</td>
            </tr>
            <tr>
              <td>Payment</td>
              <td>{{ tasktodisplayinfos.payment }} US$</td>
            </tr>
            <tr>
              <td>Deadline</td>
              <td>{{ tasktodisplayinfos.deadline }} EST</td>
            </tr>
          </mdb-tbl-body>
        </mdb-tbl>
        <mdb-row v-if="tasktodisplayinfos.srt_edited_by_client || tasktodisplayinfos.csv_edited_by_client">
          <mdb-col>
          <div v-if="tasktodisplayinfos.srt_edited_by_client">
            <hr  />
            <p class="danger-info">The client has updated your subtitles file. Please, download it again and use it when redoing your work.</p>
          </div>
          <div v-if="tasktodisplayinfos.csv_edited_by_client" >
            <hr />
            <p class="danger-info">The client has updated your motion graphics file. Please, download it again and use it when redoing your work.</p>
          </div>
          </mdb-col>
        </mdb-row>
        <mdb-row v-if="tasktodisplayinfos.instructions">
          <mdb-col>
            <hr />
            <h5><mdb-icon icon="book" /> Instructions</h5>
            <hr />
            <instructions-box :instructions="tasktodisplayinfos.instructions" />
          </mdb-col>
        </mdb-row>
        <mdb-row v-if="tasktodisplayinfos.isassigned && tasktodisplayinfos.employeeid == $store.state.id">
          <mdb-col>
            <hr />
            <div
              class="mb-2"
              v-if="
                tasktodisplayinfos.previoustasks instanceof Array &&
                tasktodisplayinfos.previoustasks.length > 0
              "
            >
              <h5><mdb-icon icon="tasks" /> Previous tasks done</h5>
              <mdb-row>
                <mdb-col>
                  <mdb-tbl class="previous-task-table">
                    <mdb-tbl-head>
                      <tr>
                        <th>
                          Id
                        </th>
                        <th>
                          Name
                        </th>
                        <th>
                          Delivery content
                        </th>
                        <th class="instructions-content">
                          Instructions
                        </th>
                      </tr>
                    </mdb-tbl-head>
                    <mdb-tbl-body>
                      <tr
                      v-for="(previousTask, i) in tasktodisplayinfos.previoustasks"
                      :key="i"
                      >
                      <td>
                        <div class="mobile-th">Id</div>
                        <div class="row-content">
                          {{ previousTask.taskid }}
                        </div>
                      </td>
                      <td>
                        <div class="mobile-th">Name</div>
                        <div class="row-content">
                          {{ previousTask.name }}
                        </div>
                      </td>
                      <td>
                        <div class="mobile-th">Delivery content</div>
                        <div class="row-content">
                          <delivery-content-viewer
                          :deliverycontent="previousTask.deliverycontent"
                          :task-type="previousTask.type"
                          >
                          <a
                            v-if="
                              (previousTask.spotcheckof &&
                              previousTask.spotcheckof.deliverycontent instanceof
                                Array &&
                              previousTask.spotcheckof.deliverycontent.filter(
                                (element) => element && element.type === 'video-file'
                              ).length > 0) ||
                              (previousTask.deliverycontent instanceof Array &&
                              previousTask.deliverycontent.filter(
                                (element) => element && element.type === 'video-file'
                              ).length > 0)
                            "
                            class="btn btn-sm btn-danger mt-1 mx-2"
                            target="__blank"
                            :href="`/tasks/${previousTask.taskid}/videoreview`"
                          >
                            <mdb-icon icon="eye"></mdb-icon> Watch in video review
                            screen
                          </a>
                          <a
                            v-if="
                              (previousTask.spotcheckof &&
                              previousTask.spotcheckof.deliverycontent instanceof
                                Array &&
                              previousTask.spotcheckof.deliverycontent.filter(
                                (element) => element && element.type === 'image-file'
                              ).length > 0) ||
                              (previousTask.deliverycontent instanceof Array &&
                              previousTask.deliverycontent.filter(
                                (element) => element && element.type === 'image-file'
                              ).length > 0)
                            "
                            class="btn btn-sm btn-danger mt-1 mx-2"
                            target="__blank"
                            :href="`/tasks/${previousTask.taskid}/photoreview`"
                          >
                            <mdb-icon icon="eye"></mdb-icon> Watch in photo review screen
                          </a>
                        </delivery-content-viewer>
                        </div>
                      </td>
                      <td class="instructions-content">
                        <div class="mobile-th">Instructions</div>
                        <div class="row-content">
                          <instructions-box v-if="previousTask.instructions" :instructions="previousTask.instructions" />
                          <p v-else>---</p>
                        </div>
                      </td>
                      </tr>
                    </mdb-tbl-body>
                  </mdb-tbl>
                </mdb-col>
              </mdb-row>
            </div>
            <div v-if="tasktodisplayinfos.required_licence && tasktodisplayinfos.licence_info || tasktodisplayinfos.optional_licence && tasktodisplayinfos.licence_info">
            <h5><mdb-icon icon="id-badge" /> Current licence</h5>
            <mdb-card>
              <mdb-card-body>
                <p v-if="tasktodisplayinfos.required_licence">Type: {{tasktodisplayinfos.required_licence}}</p>
                <p v-else-if="tasktodisplayinfos.optional_licence">Type: {{tasktodisplayinfos.optional_licence}}</p>
                <p>Username: {{tasktodisplayinfos.licence_info?.username}}</p>
                <p>Password: {{tasktodisplayinfos.licence_info?.password}}</p>
                <a class="btn btn-primary" target="_blank" href="https://evening-puppet-741.notion.site/Proc-dure-connexion-Licence-Adobe-50a05ef6d83a4ae2a610361afbd424dc">
                  Click here to see how to use this licence
                </a>
                <p>
                  <b class="text-wrap text-danger font-weight-bold">
                    Please email adriana@pluscrew.com if it asks you for a code to use the licence
                  </b>
                </p>
              </mdb-card-body>
            </mdb-card>
          </div>
            <hr />
            <hr />
            <h5><mdb-icon icon="truck" /> Delivery content of current task</h5>
            <hr />
            <mdb-spinner multicolor size="sm" v-if="loadingTaskDetails" />
            <div class="mb-2" v-else>
              <delivery-content-viewer
                :deliverycontent="tasktodisplayinfos.deliverycontent"
                :task-type="tasktodisplayinfos.type"
                />
            </div>
            <div v-if="tasktodisplayinfos.type === 'CLIENT-REVIEW'">
              <a
                v-if="tasktodisplayinfos.linkVideo !== ''"
                class="btn btn-sm btn-danger mt-1 mx-2"
                target="__blank"
                :href="tasktodisplayinfos.linkVideo"
              >
                <mdb-icon icon="eye"></mdb-icon> Check the video in the client review screen
              </a>
              <a
                v-if="tasktodisplayinfos.linkPhoto !== ''"
                class="btn btn-sm btn-danger mt-1 mx-2"
                target="__blank"
                :href="tasktodisplayinfos.linkPhoto"
              >
                <mdb-icon icon="eye"></mdb-icon> Check the images in the client review screen
              </a>
            </div>
            <hr />
            <mdb-input
              v-if="tasktodisplayinfos.tasktypeinfos.deliverables === null"
              outline
              type="textarea"
              :rows="5"
              v-model="tasktodisplayinfos.deliverycontent"
              placeholder="Insert modified text"
            />
            <div v-else>
              <div
                v-for="(deliverable, index) in tasktodisplayinfos.tasktypeinfos
                  .deliverables"
                :key="index"
              >
                <delivery-content-uploader
                  @change="setDeliveryWip(index, $event)"
                  :name="deliverable.displayname"
                  :task-id="tasktodisplayinfos.taskid"
                  :deliverable-index="index"
                  :deliverable="deliverable"
                  :retainFilename="tasktodisplayinfos?.type?.includes('UPLOAD_FILE')"
                  :resumable="true"
                  :optional="deliverable.optional"
                />
              </div>
            </div>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer>
        <div v-if="!tasktodisplayinfos.isassigned">
          By accepting to work on the following job, I confirm that I will never
          share, divulge, copy, transfer or use this content for any other means
          than those I was mandated to do. I accept to never disclose any
          information contained in this or any other task I work with.
          <br />

          <div v-if="this.$store.state.isndasigned === null">
            <br />
            <p>
              You have not yet signed the non-disclosure agreement required.
              <button class="btn-success" @click="sendnda" :disabled="disabledBtnSendNDA">Please click here to receive it by email at: {{$store.state.email}}</button>
            </p>
          </div>
          <div v-for="(buttons, index) in availabletests"
                :key="index">
            <mdb-btn color="primary" @click="$router.push(`tests/${buttons}/attempt`)" >Get {{buttons}} skill</mdb-btn>
          </div>
          <div v-for="(buttons, index) in unavailabletests"
                :key="index">
            <mdb-btn color="danger" >{{buttons}} test not available, please contact your administrator</mdb-btn>
          </div>
          <template v-if="tasktodisplayinfos.required_licence === null">
            <mdb-btn color="primary" v-on:click="takethistask" v-if="this.$store.state.isndasigned !== null && buttonslength == 0">Take this task </mdb-btn>
            <!-- <mdb-btn color="primary" v-on:click="takethistask" v-if="buttonslength == 0">Take this task </mdb-btn> -->
          </template>
          <template v-else>
            <b class="text-wrap text-danger font-weight-bold">
              THIS TASK REQUIRES YOU TO USE ONE OF OUR ADOBE LICENCES
            </b>
            <mdb-btn color="primary" v-on:click="requestLicenceAndTakethistask" v-if="this.$store.state.isndasigned !== null && buttonslength == 0">Take this task</mdb-btn>
          </template>
          <mdb-btn outline="info" @click="showtaskinfos=false;tasktodisplayinfos={};">Close</mdb-btn>
        </div>
        <div v-else>
          <mdb-btn
            color="primary"
            v-if="tasktodisplayinfos.optional_licence && !tasktodisplayinfos.licence_info"
            v-on:click="requestLicence"
            :disabled="loadingTaskDetails"
            >Request a Licence ({{tasktodisplayinfos.optional_licence?.replace('_', ' ')}})
          </mdb-btn>
          <mdb-btn
            color="danger"
            v-if="tasktodisplayinfos.isspotcheck"
            v-on:click="scheduleTaskOpenModal"
            :disabled="loadingTaskDetails"
            >Failed, make the person do the work again <mdb-icon icon="thumbs-down" /></mdb-btn
          >
          <mdb-btn :disabled="loadingTaskDetails || loadingConfirmTaskDone" v-on:click="confirmJobDone"
            >I confirm this job is completed <mdb-icon icon="thumbs-up" />
            <mdb-spinner size="sm" multicolor v-if="loadingConfirmTaskDone" />
          </mdb-btn>
        </div>
        <div v-if="tasktodisplayinfos.optional_licence && !tasktodisplayinfos.licence_info">
           <b class="text-wrap text-danger font-weight-bold">
              Please email adriana@pluscrew.com if it asks you for a code to use the licence
            </b>
        </div>
      </mdb-modal-footer>
    </mdb-modal>
    <mdb-modal :show="openTaskScheduleModal" @close="openTaskScheduleModal = false">
      <mdb-modal-header>Please add the exact time you will return the task:</mdb-modal-header>
      <mdb-modal-body>
        <mdb-input type="date" label="Date" id="send-back-date" v-model="sendBackDate" />
        <mdb-input type="time" label="Time" id="send-back-time" v-model="sendBackTime" />
        <mdb-input type="checkbox" label="Send back now" value="1" id="send-back-now" v-model="sendBackNowChecker" />
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn outline="info" @click="openTaskScheduleModal=false">Cancel</mdb-btn>
        <mdb-btn color="success" v-on:click="sendBackFailSpotcheckTask" :disabled="isSendBackLoading">OK</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <soustitreur-signup-modal v-model="showSoustitreurSignupModal" :job-id="soustitreurTaskId" :email="$store.state.email" @success="openNewTab($event)"/>
  </mdb-container>
</template>

<script>
import {
  mdbContainer,
  mdbAlert,
  mdbIcon,
  mdbRow,
  mdbCol,
  mdbCard,
  mdbCardHeader,
  mdbCardBody,
  mdbSpinner,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
  mdbBtn,
  mdbInput,
} from 'mdbvue';
import DeliveryContentUploader from '@/components/DeliveryContentUploader.vue';
import DeliveryContentViewer from '@/components/DeliveryContentViewer.vue';
import SoustitreurSignupModal from '@/components/SoustitreurSignupModal.vue';
import Header from '@/components/Header.vue';
import InstructionsBox from '@/components/InstructionsBox.vue';
import Apicall from '@/libs/Apicall';

export default {
  name: 'Home',
  components: {
    DeliveryContentUploader,
    DeliveryContentViewer,
    Header,
    InstructionsBox,
    mdbContainer,
    mdbAlert,
    mdbIcon,
    mdbRow,
    mdbCol,
    mdbCard,
    mdbCardHeader,
    mdbCardBody,
    mdbSpinner,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbBtn,
    mdbInput,
    SoustitreurSignupModal,
  },
  data() {
    return {
      error: '',
      dummy: '',
      licenceInfo: null,
      soustitreurTaskId: null,
      showSoustitreurSignupModal: false,
      availjobsloading: true,
      availjobssoustitreurloading: true,
      loadingTaskDetails: false,
      savingDeliveryContent: false,
      availabletasks: [],
      employeeskills: [],
      showtaskinfos: false,
      openTaskScheduleModal: false,
      tasktodisplayinfos: {},
      assignedjobsloading: true,
      sendBackDate: null,
      sendBackTime: null,
      sendBackNowChecker: false,
      availabletasksqueues: [],
      inprocesstasksqueues: [],
      assignedjobs: [],
      availabletests: [],
      unavailabletests: [],
      assignedsoustitreurjobs: [],
      disabledBtnSendNDA: false,
      loadingConfirmTaskDone: false,
      attemptbuttons: [],
      buttonslength: 1,
      positionInWeeklyContest: null,
      tasks: null,
      sendBackDateAndTime: null,
      nextSaturday: null,
      isSendBackLoading: false,
    };
  },
  mounted() {
    this.loadAvailableTasks();
    this.loadMyAssignedTasks();
    this.loadSoustitreurTasks();
    this.getGetPositionChallengingTasks();
    if (this.$route.query.taskid) {
      this.showTaskTodoInfos(this.$route.query.taskid);
    }
  },
  computed: {},
  watch: {
    sendBackDate(newVal) {
      if (newVal && this.sendBackNowChecker) {
        this.sendBackNowChecker = false;
      }
    },
    sendBackTime(newVal) {
      if (newVal && this.sendBackNowChecker) {
        this.sendBackNowChecker = false;
      }
    },
    sendBackNowChecker(newVal) {
      if (newVal) {
        this.sendBackDate = null;
        this.sendBackTime = null;
      }
    },
  },
  methods: {
    scheduleTaskOpenModal() {
      this.openTaskScheduleModal = true;
    },
    loadAvailableTasks() {
      this.availjobsloading = true;
      const self = this;
      const api = new Apicall();
      self.error = '';
      api.call('GET', 'api/task/available-for-me').then((response) => {
        if (response.status === 'OK') {
          self.availabletasks = response.data.tasks;
          self.employeeskills = response.data.skills;
        } else {
          self.error = response.msg;
        }
        this.availjobsloading = false;
      });
    },
    loadMyAssignedTasks() {
      this.assignedjobsloading = true;
      const self = this;
      const api = new Apicall();
      self.error = '';
      api.call('GET', 'api/task/my-current-tasks').then((response) => {
        if (response.status === 'OK') {
          self.assignedjobs = response.data;
        } else {
          self.error = response.msg;
        }
        this.assignedjobsloading = false;
      });
    },
    loadSoustitreurTasks() {
      this.assignedjobsloading = true;
      const self = this;
      const api = new Apicall();
      self.error = '';
      api
        .call('GET', 'api/soustitreur/available-tasks-for-me')
        .then((response) => {
          if (response.status === 'OK') {
            self.assignedsoustitreurjobs = response.data.taskinfos;
            self.availjobssoustitreurloading = false;
          } else {
            self.error = response.msg;
          }
          this.assignedjobsloading = false;
        });
    },
    getGetPositionChallengingTasks() {
      const apiCall = new Apicall();

      apiCall.call('GET', 'api/task/get-position-challenging-tasks', null)
        .then((response) => {
          if (response.data.status === 'OK') {
            this.positionInWeeklyContest = response.data.positionChallengingTasks;
            this.tasks = response.data.tasks;
            this.nextSaturday = response.data.nextSaturday;
          }
        });
    },
    setDeliveryWip(index, fileinfo) {
      if (!(this.tasktodisplayinfos.deliverycontent instanceof Array)) {
        this.tasktodisplayinfos.deliverycontent = [];
      }

      this.tasktodisplayinfos.deliverycontent[fileinfo.deliverableindex] = this.tasktodisplayinfos.tasktypeinfos.deliverables[
          fileinfo.deliverableindex
        ];
      this.tasktodisplayinfos.deliverycontent[
        fileinfo.deliverableindex
      ].filename = fileinfo.filename;
      this.tasktodisplayinfos.deliverycontent[
        fileinfo.deliverableindex
      ].public = fileinfo.public;

      this.tasktodisplayinfos.deliverycontent[
        fileinfo.deliverableindex
      ].size = fileinfo.filesize;

      if (fileinfo.originalFilename) {
        this.tasktodisplayinfos.deliverycontent[
          fileinfo.deliverableindex
        ].originalFilename = fileinfo.originalFilename;
      }

      const apiCall = new Apicall();
      this.loadingTaskDetails = true;

      apiCall
        .call('POST', 'api/task/save-delivery-content', {
          taskid: this.tasktodisplayinfos.taskid,
          taskdeliverycontent: this.tasktodisplayinfos.deliverycontent,
          deliverableindex: fileinfo.deliverableindex,
          fileIsZip: fileinfo.fileIsZip,
          filesize: fileinfo.filesize,
        })
        .then((response) => {
          if (response.status === 'OK') {
            this.showtaskinfos = true;
            this.tasktodisplayinfos = response.data;
          } else {
            this.notificationError(response.msg);
          }
        })
        .finally(() => {
          this.loadingTaskDetails = false;
        });
    },
    async showTaskTodoInfos(_taskid) {
      const self = this;
      const api = new Apicall();
      self.error = '';
      self.showtaskinfos = false;
      this.loadingTaskDetails = true;
      await this.updateTaskDeliveryContent(_taskid);
      await api
        .call('POST', 'api/task/infos', { taskid: _taskid })
        .then((response) => {
          if (response.status === 'OK') {
            if (response.data.isassigned) {
              if (response.data.employeeid !== this.$store.state.id) {
                self.error = 'You can\'t access the informations of this task. Maybe somebody else already took this task. Reload the page';
                return;
              }
            }
            self.buttonslength = response.data.buttonstoshow.availables.length;
            self.buttonslength += response.data.buttonstoshow.unavailables.length;
            self.showtaskinfos = true;
            self.tasktodisplayinfos = response.data;
            self.availabletests = response.data.buttonstoshow.availables;
            self.unavailabletests = response.data.buttonstoshow.unavailables;
          } else {
            self.error = response.msg;
          }
        })
        .finally(() => {
          this.loadingTaskDetails = false;
        });
    },
    async updateTaskDeliveryContent(_taskid) {
      const self = this;
      const api = new Apicall();
      await api
        .call('POST', 'api/task/check-task-delivery-content-has-valid-zip', { taskId: _taskid })
        .then((response) => {
          if (response.status !== 'OK') {
            self.error = response.msg;
          }
        });
    },
    takethistask() {
      const self = this;
      const api = new Apicall();
      api
        .call('POST', 'api/task/take-this-task', {
          taskid: this.tasktodisplayinfos.taskid,
        })
        .then((response) => {
          self.showtaskinfos = false;
          if (response.status === 'OK') {
            self.loadAvailableTasks();
            self.loadMyAssignedTasks();
          } else {
            self.error = response.msg;
          }
        });
    },
    requestLicenceAndTakethistask() {
      const self = this;
      const api = new Apicall();
      api
        .call('POST', 'api/task/take-this-task', {
          taskid: this.tasktodisplayinfos.taskid,
          request_licence: true,
        })
        .then((response) => {
          self.showtaskinfos = false;
          if (response.status === 'OK') {
            if (response.data.licence_info) {
              this.licenceInfo = response.data.licence_info;
            }
            self.loadAvailableTasks();
            self.loadMyAssignedTasks();
          } else {
            self.error = response.msg;
          }
        });
    },
    requestLicence() {
      const self = this;
      const api = new Apicall();
      api
        .call('POST', 'api/task/request-licence', {
          taskid: this.tasktodisplayinfos.taskid,
          request_licence: true,
        })
        .then((response) => {
          self.showtaskinfos = false;
          if (response.status === 'OK') {
            if (response.data.licence_info) {
              this.licenceInfo = response.data.licence_info;
            }
            self.loadAvailableTasks();
            self.loadMyAssignedTasks();
          } else {
            self.error = response.msg;
          }
        });
    },
    confirmJobDone() {
      const self = this;
      const api = new Apicall();
      this.loadingConfirmTaskDone = true;
      api
        .call('POST', 'api/task/confirm-task-done', {
          taskid: this.tasktodisplayinfos.taskid,
          taskdeliverycontent: this.tasktodisplayinfos.deliverycontent,
        })
        .then((response) => {
          this.loadingConfirmTaskDone = false;
          self.showtaskinfos = false;
          if (response.status === 'OK') {
            self.loadAvailableTasks();
            self.loadMyAssignedTasks();
          } else {
            self.error = response.msg;
          }
        });
    },
    convertSendBackDate() {
      this.sendBackDateAndTime = `${this.sendBackDate} ${this.sendBackTime}:00`;
    },
    sendBackFailSpotcheckTask() {
      const self = this;
      const api = new Apicall();

      if (self.sendBackNowChecker) {
        self.failSpotcheckTask();
        self.openTaskScheduleModal = false;
        return;
      }

      console.log(self.sendBackNowChecker);

      if (!self.sendBackDate && !self.sendBackNowChecker) {
        self.notificationError('You have to add a date');
      }
      if (!self.sendBackTime && !self.sendBackNowChecker) {
        self.notificationError('You have to add a time');
      }

      if (self.sendBackDate && self.sendBackTime) {
        self.convertSendBackDate();
        const now = new Date();
        const sendBackDatetime = new Date(self.sendBackDateAndTime);
        if (sendBackDatetime > now) {
          self.isSendBackLoading = true;
          api
            .call('POST', 'api/task/create-task-schedule', {
              taskId: this.tasktodisplayinfos.taskid,
              sendBackDateAndTime: this.sendBackDateAndTime,
              taskDeliveryContent: this.tasktodisplayinfos.deliverycontent,
            })
            .then((response) => {
              if (response.status === 'OK') {
                self.showtaskinfos = false;
                self.openTaskScheduleModal = false;

                self.loadAvailableTasks();
                self.loadMyAssignedTasks();
              } else {
                self.error = response.msg;
              }
            }).finally(() => {
              self.isSendBackLoading = false;
            });
        } else {
          self.notificationError('You must add a valid date');
        }
      }
    },
    failSpotcheckTask(msg = null) {
      const self = this;
      const api = new Apicall();
      this.loadingTaskDetails = true;
      api
        .call('POST', 'api/task/fail-spotcheck', {
          taskid: this.tasktodisplayinfos.taskid,
          taskdeliverycontent: this.tasktodisplayinfos.deliverycontent,
        })
        .then((response) => {
          this.loadingTaskDetails = false;

          self.showtaskinfos = false;
          if (response.status === 'OK') {
            self.loadAvailableTasks();
            self.loadMyAssignedTasks();
          } else {
            self.error = response.msg;
          }
        });
    },
    confirmAbandonTask() {
      if (window.confirm('Are you sure you want to abandon this task?')) {
        const self = this;
        const api = new Apicall();
        api
          .call('POST', 'api/task/abandon-task', {
            taskid: this.tasktodisplayinfos.taskid,
          })
          .then((response) => {
            self.showtaskinfos = false;
            if (response.status === 'OK') {
              self.loadAvailableTasks();
              self.loadMyAssignedTasks();
            } else {
              self.error = response.msg;
            }
          });
      }
    },
    nl2br(str) {
      if (str) {
        return str.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br />$2');
      }
      return '';
    },
    openNewTab(_href) {
      window.open(_href, '_blank');
    },
    openSoustitreurJob(jobUrl, jobid) {
      const self = this;
      const api = new Apicall();
      self.error = '';
      api
        .call('GET', 'api/soustitreur/email-is-registered')
        .then((response) => {
          if (response.status === 'OK') {
            if (response.data.isregistered) {
              api.call('POST', 'api/soustitreur/login', { jobid })
              .then((responseLogin) => {
                self.soustitreurTaskId = null;
                if (responseLogin.status === 'OK') {
                  this.openNewTab(responseLogin.data.loginurl);
                }
              });
            } else {
              self.soustitreurTaskId = jobid;
              self.showSoustitreurSignupModal = true;
            }
          } else {
            self.error = response.msg;
          }
        });
    },
    sendnda() {
      const api = new Apicall();
      api.call('POST', 'api/employee/send-nda', { email: this.$store.state.email }).then((response) => {
        if (response.status === 'OK') {
          const sendndasuccess = `We just sent a NDA contract to sign by email at ${this.$store.state.email}. Please sign it to gain access to tasks. Access will be granted right after the signature.`;
          this.notificationSusscess(sendndasuccess);
          this.disabledBtnSendNDA = true;
        } else {
          this.notificationError(response.msg);
        }
      });
    },
    notificationSusscess(_messsage) {
      this.$notify.success({ message: _messsage, position: 'top right', timeOut: 5000 });
    },
    notificationError(_messsage) {
      this.$notify.error({ message: _messsage, position: 'top right', timeOut: 5000 });
    },
  },
};
</script>

<style scoped>
tbody.main tr:hover {
  background-color: #f5f5f5;
}

.danger-info {
  font-weight: bold;
  color: #DC3545;
}
.title-max-width {
  max-width: 300px;
}
.instructions-content {
  max-width: 300px;
}
.previous-task-table {
  max-height: 400px;
  overflow-y: auto;
}
.mobile-th {
  display: none;
}
@media screen and (max-width: 990px) {
  .previous-task-table tr {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0.5rem 0;
    border: 1px solid #03030333;
  }
  .previous-task-table td {
    width: 100%;
    display: flex;
  }
  .previous-task-table th {
    display: none;
  }
  .previous-task-table td .row-content {
    width: 70%;
    word-break: break-all;
  }
  .previous-task-table td .mobile-th {
    display: block;
    width: 30%;
    word-break: normal;
  }
}
</style>
