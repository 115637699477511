<template>
  <mdb-container fluid>
    <mdb-modal :show="openModal" @close="openModal = false">
      <mdb-modal-header>Tell the employee what is wrong with the task:</mdb-modal-header>
      <mdb-modal-body>
        <div v-if="selectedTaskInfo && selectedTaskInfo.deliverycontent !== null">
          <div v-for="(deliverable, ind) in selectedTaskInfo.deliverycontent" :key="ind">
            <mdb-input v-if="deliverable" :label="deliverable.displayname" :id="`textarea_deliverable_${ind}`" type="textarea"></mdb-input>
          </div>
        </div>
        <div v-else >
          <mdb-input type="textarea" :id="`textarea_deliverable`" v-model="textareaWrongTask"></mdb-input>
        </div>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn outline="info" @click="openModal=false">Cancel</mdb-btn>
        <mdb-btn color="success" v-on:click="sendBackToEmployee(selectedTaskInfo.taskid)">Send message</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <mdb-modal :show="openTaskScheduleModal" @close="openTaskScheduleModal = false">
      <mdb-modal-header>Edit Task Schedule</mdb-modal-header>
      <mdb-modal-body>
        <mdb-input type="date" id="send-back-date" v-model="sendBackDate" />
        <mdb-input type="time" id="send-back-time" v-model="sendBackTime" />
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn outline="info" @click="openTaskScheduleModal=false">Cancel</mdb-btn>
        <mdb-btn color="success" v-on:click="editTaskSchedule(taskScheduleEditableInfo.id)">Edit Task Schedule</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <Header></Header>
    <section class="mt-3">
      <mdb-alert v-if="error !== null" color="danger" >{{ error }}</mdb-alert>
      <mdb-spinner v-if="!taskqueue || !project || !taskqueue.project" multicolor></mdb-spinner>
      <form v-else @change="error = null">
      <mdb-row>
        <mdb-col md="6">
          <mdb-card>
            <mdb-card-header color="grey accent-3 d-flex justify-content-between align-items-center">
              Task queue # {{ taskqueueId }}
              <div>
                <span @click="showDuplicateTaskQueueModal=true;" style="font-size: 1.2rem; margin-right: .5rem; cursor: pointer;"><mdb-badge color="success" style="padding: .6rem;">DUPLICATE TASK QUEUE</mdb-badge></span>
                <span style="font-size: 1.5rem;"><mdb-badge v-if="taskqueue.deleted_at !== null" color="danger">DELETED</mdb-badge></span>
              </div>
            </mdb-card-header>
            <mdb-card-body>
                <div v-if="submitting">
                  <span class="spinner-border text-success" role="status">
                    <span class="sr-only">Saving...</span>
                  </span> Saving...
                </div>
                <mdb-tbl responsive sm>
                  <mdb-tbl-body class="main">
                    <tr><td style="font-weight: bold;">Task queue ID</td><td>{{taskqueueId}}</td></tr>
                    <tr>
                      <td style="font-weight: bold;">Task queue Name</td>
                      <td v-if="$store.state.accesslevel !== 'FULLACCESS'">{{ taskqueueName }}</td>
                      <td v-else>
                        <mdb-input label="Taskqueue Name" v-model="taskqueueName" @change="autoSaveTaskqueueName" />
                      </td>
                    </tr>
                    <template v-if="taskqueue.project">
                      <tr><td style="font-weight: bold;">Project Name</td><td @click="goToProjectPage(taskqueue)" style="cursor: pointer">{{taskqueue.project?.name}}</td></tr>
                      <tr><td style="font-weight: bold;">Stripe Invoice</td><td>{{stripeInvoice}}</td></tr>
                      <tr><td style="font-weight: bold;">Is Paid?</td><td>{{taskqueue.ledgerid !== null ? "Yes." : "No."}}</td></tr>
                      <tr><td style="font-weight: bold;">Total invoice</td><td>{{billingUnitQuantity}}</td></tr>
                      <tr><td style="font-weight: bold;">Added at</td><td>{{taskqueue.addedat}}</td></tr>
                      <tr><td style="font-weight: bold;">RUSH?</td><td><mdb-input type="checkbox" id="chk_isrush" v-model="taskqueue.isrush" @change="autoSaveChangeRush" /></td></tr>
                      <tr><td style="font-weight: bold;">TO FOLLOW?</td><td><mdb-switch v-model="taskqueue.istofollow" @input="autoSaveToFollow" offLabel="No" onLabel="Yes" /></td></tr>
                      <tr><td style="font-weight: bold;">Customer</td><td><a @click="goToCustomerDetails(taskqueue.project?.client.id)">{{taskqueue.project?.client.firstname}} {{taskqueue.project.client.lastname}} </a><mdb-icon icon="external-link-alt"/></td></tr>
                      <tr><td style="font-weight: bold;">Customer Email</td><td @click="copyToClipboard(taskqueue.project?.client.email)">{{taskqueue.project?.client.email}} <mdb-icon icon="clone"/></td></tr>
                      <tr><td style="font-weight: bold;">Project Id</td><td>{{taskqueue.projectid}}</td></tr>
                      <tr><td style="font-weight: bold;">Tasks done</td><td>{{taskqueue.tasksdone}}</td></tr>
                      <tr><td style="font-weight: bold;">Total tasks</td><td>{{taskqueue.totaltasks}}</td></tr>
                      <tr v-if="taskqueue.linkTaskqueue">
                        <td style="font-weight: bold;">Continue from</td>
                        <td><a :href="`/admin/tasks/edit/${taskqueue.linkTaskqueue.taskqueueid}`" title="Got to taskqueue" target="_blank">#{{taskqueue.linkTaskqueue.taskqueueid}} {{taskqueue.linkTaskqueue.queuename}}</a></td>
                      </tr>
                      <tr v-if="taskqueue.iscompleted && taskqueue.reviewOptions?.length">
                        <td style="font-weight: bold;">Client review</td>
                        <td>
                          <a target="_blank" v-for="(reviewOption, index) in taskqueue.reviewOptions" class="btn btn-secondary" :key="index" :href="reviewOption.url">{{reviewOption.displayname}}</a>
                        </td>
                      </tr>
                      <tr v-if="taskqueue.deleted_at !== null"><td  style="font-weight: bold;">Deleted At</td><td>{{ taskqueue.deleted_at }}</td></tr>
                      <tr><td style="font-weight: bold;">Internal notes</td><td><mdb-input type="textarea" v-model="taskqueue.internalnotes" :rows=4 @change="autoSaveInternalNotes" /><small>{{ internalNotesInputStatus }}</small></td></tr>
                    </template>
                  </mdb-tbl-body>
                </mdb-tbl>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
        <mdb-col md="6">
          <mdb-card>
            <mdb-card-header color="green accent-3">Task info</mdb-card-header>
            <mdb-card-body v-if="!loadingClientReview">
              <div>
                <mdb-tbl responsive sm>
                  <mdb-tbl-body v-if="selectedTaskInfo" class="main">
                    <tr><td style="font-weight: bold;">Task ID</td><td>{{selectedTaskInfo.taskid}}</td></tr>
                    <tr><td style="font-weight: bold;">Type</td><td>{{selectedTaskInfo.type}}</td></tr>
                    <tr><td style="font-weight: bold;">Name</td><td>{{selectedTaskInfo.name}}</td></tr>
                    <tr><td style="font-weight: bold;">Skills</td><td>{{selectedTaskInfo.skills}}</td></tr>
                    <tr><td style="font-weight: bold;">Instructions</td><td><instructions-box :instructions="selectedTaskInfo.instructions"/></td></tr>
                    <tr v-if="selectedTaskInfo.employee && selectedTaskInfo.ispaid == 'Yes'"><td style="font-weight: bold;">Employee</td><td><a @click="goToEmployeeDetails(selectedTaskInfo.employee.id)">{{selectedTaskInfo.employee.firstname}} {{selectedTaskInfo.employee.lastname}} </a><mdb-icon icon="external-link-alt"/></td></tr>
                    <tr v-if="selectedTaskInfo.employee && selectedTaskInfo.ispaid == 'Yes'"><td style="font-weight: bold;">Employee email</td><td><a @click="copyToClipboard(selectedTaskInfo.employee.email)">{{selectedTaskInfo.employee.email}}<mdb-icon icon="clone"/></a></td></tr>
                    <tr v-if="selectedTaskInfo.employee && selectedTaskInfo.ispaid == 'Yes'"><td style="font-weight: bold;">Paid to employee on</td><td>{{selectedTaskInfo.paidat}}</td></tr>
                    <tr><td style="font-weight: bold;">Delivery Content</td>
                      <td>
                        <delivery-content-viewer :deliverycontent="selectedTaskInfo.deliverycontent" :task-type="selectedTaskInfo.type" mark-public>
                          <a
                            v-if="
                              selectedTaskInfo.type === 'SPOTCHECK_VIDEO' ||
                              selectedTaskInfo.type === 'VIDEO_EDITINGR' ||
                              (tasks.length > taskInfoIndex + 1 &&
                                (tasks[taskInfoIndex + 1].type === 'SPOTCHECK_VIDEO' ||
                                tasks[taskInfoIndex + 1].type === 'VIDEO_EDITINGR') &&
                                tasks[taskInfoIndex + 1].completedat)
                            "
                            class="btn btn-sm btn-danger mt-1 mx-2"
                            target="__blank"
                            :href="`/tasks/${selectedTaskInfo.taskid}/videoreview`"
                          >
                            <mdb-icon icon="eye"></mdb-icon> Watch in video review screen
                          </a>
                          <a
                            v-if="
                              selectedTaskInfo.containsImage ||
                              selectedTaskInfo.spotcheckof?.containsImage
                            "
                            class="btn btn-sm btn-danger mt-1 mx-2"
                            target="__blank"
                            :href="`/tasks/${selectedTaskInfo.taskid}/photoreview`"
                          >
                            <mdb-icon icon="eye"></mdb-icon> See in photo review screen
                          </a>
                        </delivery-content-viewer>
                      </td>
                    </tr>
                    <tr v-if="tasktodisplayinfos.hasexternalupload">
                      <td>Upload deliverables externally</td>
                      <td>
                        <mdb-btn color="primary" @click="uploadDeliverablesExternally(tasktodisplayinfos.taskid)">Upload deliverables</mdb-btn>
                      </td>
                    </tr>
                    <tr v-if="tasktodisplayinfos?.srt_edited_by_client">
                    <td></td>
                    <td class="danger-info">The client has updated your subtitles file. Please download it again and use it when redoing your work.</td>
                    </tr>
                    <tr v-if="tasktodisplayinfos?.csv_edited_by_client">
                    <td></td>
                    <td class="danger-info">The client has updated your motion graphics file. Please download it again and use it when redoing your work.</td>
                    </tr>
                    <tr><td style="font-weight: bold;">Can be dispatched</td><td><mdb-input type="checkbox" id="chk_canbedispatched" v-model="selectedTaskInfo.canbedispatched" :checked="selectedTaskInfo.canbedispatched=='Yes'" @change="autoSaveCanBeDispatched()" /></td></tr>
                    <tr><td style="font-weight: bold;">Added at</td><td>{{selectedTaskInfo.addedat}}</td></tr>
                    <tr><td style="font-weight: bold;">Completed at</td><td>{{selectedTaskInfo.completedat}}</td></tr>
                    <tr><td style="font-weight: bold;">Paid at</td><td v-if="selectedTaskInfo.paidat!==null">{{selectedTaskInfo.paidat}}</td><td v-else>UNPAID</td></tr>
                    <tr>
                      <td style="font-weight: bold;">Assigned Employee</td>
                      <td>
                        <div  v-if="selectedTaskInfo.employee && selectedTaskInfo.employee.email" v-on:click.prevent="copyToClipboard(selectedTaskInfo.employee.email)">
                          <a>{{ selectedTaskInfo.employee.firstname + ' ' + selectedTaskInfo.employee.lastname }} - </a>
                          <a>{{selectedTaskInfo.employee.email}} <mdb-icon icon="clone"/></a>
                        </div>
                        <mdb-btn size="sm" v-on:click="removeEmployeeFromTask(selectedTaskInfo.taskid)" :disabled="!selectedTaskInfo.employee" >UNASSIGN</mdb-btn>
                        <mdb-btn size="sm" v-on:click="showchooseEmployee">CHANGE EMPLOYEE</mdb-btn>
                        <mdb-btn size="sm" v-on:click="markAsCompleted(selectedTaskInfo.taskid)" :disabled="!selectedTaskInfo.employee" >MARK AS COMPLETED</mdb-btn>
                        <mdb-btn v-on:click="openModal=true;" v-if="isButtonVisible && selectedTaskInfo.completedat" size="sm" :disabled="!selectedTaskInfo.employee" color="red"><mdb-icon icon="thumbs-down"></mdb-icon> Send back to employee with comments</mdb-btn>
                      </td>
                    </tr>
                    <tr v-if="selectedTaskInfo.type === 'CLIENT-REVIEW'">
                      <td>
                        Client review
                      </td>
                      <td>
                          <a
                            v-if="tasktodisplayinfos?.linkVideo !== ''"
                            class="btn btn-sm btn-secondary mt-1 mx-2"
                            target="__blank"
                            :href="tasktodisplayinfos?.linkVideo"
                          >
                            🎥 Video review
                          </a>
                          <a
                            v-if="tasktodisplayinfos?.linkPhoto !== ''"
                            class="btn btn-sm btn-secondary mt-1 mx-2"
                            target="__blank"
                            :href="tasktodisplayinfos?.linkPhoto"
                          >
                            📷 Photo review
                          </a>
                      </td>
                    </tr>
                  </mdb-tbl-body>
                </mdb-tbl>
              </div>
            </mdb-card-body>
            <mdb-card-body v-else-if="loadingClientReview"><mdb-spinner multicolor></mdb-spinner></mdb-card-body>
          </mdb-card>
        </mdb-col>
        <mdb-col class="d-flex justify-content-end" v-if="taskqueue.deleted_at === null">
          <mdb-btn color="red" @click="showConfirmCancelOrder=true">Cancel order</mdb-btn>
        </mdb-col>
      </mdb-row>
    <mdb-row>
    <h3>Tasks
        <mdb-btn color="success" @click="addTask()" size="sm">
          <mdb-icon icon="plus" />
        </mdb-btn>
    </h3>
    </mdb-row>
      <mdb-row>
        <mdb-col md="6">
          <mdb-tbl responsive>
              <mdb-tbl-head>
                <tr>
                  <th style="font-weight: bold;">Name</th>
                  <th style="font-weight: bold;">Type</th>
                  <th style="font-weight: bold;">Dispatched to</th>
                  <th style="font-weight: bold;">Action</th>
                </tr>
              </mdb-tbl-head>
              <mdb-tbl-body class="lesitems" v-if="tasks.length > 0">
                <tr v-for="(task, index) in tasks" :key="task.id" @click="setSelectedTaskInfo(task, index)" style="cursor: pointer;" v-bind:class="{ 'active': task.taskid === selectedTaskInfo.taskid, 'blackcolor': task.completedat===null, 'lightgraycolor': task.completedat!==null, 'red lighten-2': task.deletedat !== null }">
                  <td><span v-if="task.completedat!==null"><mdb-icon icon="check" class="green-text pr-1" /></span># {{ index + 1 }} {{task.name}}</td>
                  <td>{{task.type}}</td>
                  <td v-if="!task.employeeid || !task.employee"><strong style="color:RED;">--PERSONNE--</strong></td>
                  <td v-else>{{task.employee.firstname}} {{task.employee.lastname}} <mdb-badge style="margin: 0 5px;" v-if="task.employee.in_probation == '1'" color="danger">IN PROBATION</mdb-badge></td>
                  <td>
                    <div class="d-flex align-items-center" v-if="task.taskqueueid === taskqueueId || task.taskid === null">
                      <div class="d-inline-block">
                        <mdb-btn
                          class="task-action-btn"
                          size="sm"
                          color="info"
                          @click="
                            setSelectedTask(task, index)
                          "
                        >
                          <span
                            v-if="showIndex === index"
                            class="
                              spinner-border
                              spinner-border-sm
                            "
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <mdb-icon v-else icon="eye" />
                        </mdb-btn>
                      </div>
                        <template
                          v-if="tasks.length > 1"
                        >
                          <div class="d-inline-block">
                            <mdb-btn
                              v-if="index > 0"
                              class="task-action-btn"
                              size="sm"
                              color="primary"
                              @click="
                                moveTask(index, index - 1)
                              "
                            >
                              <mdb-icon icon="arrow-up" />
                            </mdb-btn>
                            <mdb-btn
                              v-else
                              class="task-action-btn"
                              size="sm"
                              color="elegant"
                              disabled
                              ><mdb-icon icon="arrow-up"
                            /></mdb-btn>
                          </div>
                          <div class="d-inline-block">
                            <mdb-btn
                              v-if="
                                index !== tasks.length - 1
                              "
                              class="task-action-btn"
                              size="sm"
                              color="primary"
                              @click="
                                moveTask(index, index + 1)
                              "
                            >
                              <mdb-icon
                                icon="arrow-down"
                              />
                            </mdb-btn>
                            <mdb-btn
                              v-else
                              class="task-action-btn"
                              size="sm"
                              color="elegant"
                              disabled
                              ><mdb-icon
                                icon="arrow-down"
                            /></mdb-btn>
                          </div>
                        </template>
                      <div class="d-inline-block">
                        <mdb-btn
                          class="task-action-btn"
                          size="sm"
                          color="warning"
                          title="Restart Taskqueue from here"
                          @click="
                            showConfirmRestartWorkFromTask=true;
                            modifyTaskClickedToRestart(task)
                          "
                          ><mdb-icon icon="power-off"
                        /></mdb-btn>
                      </div>
                      <div class="d-inline-block">
                        <mdb-btn
                          class="task-action-btn"
                          size="sm"
                          color="danger"
                          @click="
                            deleteTask(index)
                          "
                          ><mdb-icon icon="trash-alt"
                        /></mdb-btn>
                      </div>
                    </div>
                    <div v-else>
                      From #{{ taskqueue.linkTaskqueue?.taskqueueid }} {{ taskqueue.linkTaskqueue?.queuename }}
                    </div>
                  </td>
                </tr>
              </mdb-tbl-body>
            </mdb-tbl>
          </mdb-col>
          <mdb-col md="6">
            <h3 style="margin-top: 2rem;">Task Schedules</h3>
            <mdb-tbl hover>
              <mdb-tbl-head>
                <tr>
                  <th>Scheduled date</th>
                  <th>Status</th>
                  <th>Employee</th>
                  <th>Action</th>
                </tr>
              </mdb-tbl-head>
              <mdb-tbl-body v-if="taskSchedule">
                <tr
                v-for="(schedule, index) in taskSchedule" :key="index">
                  <td>{{ schedule.sendback_date }}</td>
                  <td>{{ schedule.status }}</td>
                  <td>{{ schedule.employee_email }}</td>
                  <td v-if="schedule.status === 'TODO'">
                        <mdb-btn
                          class="task-action-btn"
                          size="sm"
                          color="info"
                          @click="
                            getTaskScheduleInfo(schedule, index);
                          "
                        >
                          <mdb-icon icon="edit" />
                        </mdb-btn>
                      </td>
                  <td v-else></td>
                </tr>
              </mdb-tbl-body>
            </mdb-tbl>
            <h3 style="margin-top: 5.5rem;">File Upload Tasks</h3>
            <mdb-tbl hover>
              <mdb-tbl-head>
                <tr>
                  <th>Added At</th>
                  <th>Upload to</th>
                  <th>Status</th>
                  <th style="text-align: center;">Filenames</th>
                </tr>
              </mdb-tbl-head>
              <mdb-tbl-body v-if="fileUploadTasks">
                <tr
                v-for="(fileUploadTask, index) in fileUploadTasks" :key="index">
                  <td style="padding-top: 2rem;">{{ fileUploadTask.added_at }}</td>
                  <td style="padding-top: 2rem;">{{ fileUploadTask.body.uploadTo }}</td>
                  <td style="padding-top: 2rem;">{{ fileUploadTask.status }}</td>
                  <td>
                    <mdb-list-group>
                      <mdb-list-group-item
                        v-for="(file, index) in fileUploadTask.body.files"
                        :key="index"
                        class="table-size m-0">
                        <p v-if="file.gdriveMetadata" style="word-break: break-all; white-space: normal;">{{ file.gdriveMetadata.name }}</p>
                        <p v-if="file.frameIoMetadata" style="word-break: break-all; white-space: normal;">{{ file.frameIoMetadata.name }}</p>
                      </mdb-list-group-item>
                    </mdb-list-group>
                  </td>
                </tr>
              </mdb-tbl-body>
            </mdb-tbl>
            <h3 style="margin-top: 5.5rem;">Task Events</h3>
            <mdb-tbl hover responsive>
                    <mdb-tbl-head>
                      <tr>
                        <th style="max-width: 23rem;">DateTime</th>
                        <th style="max-width: 25rem;">Event</th>
                        <th>Employee</th>
                        <th style="max-width: 25rem; width: 23rem;">Details</th>
                      </tr>
                    </mdb-tbl-head>
                    <mdb-tbl-body v-if="taskevent">
                      <tr
                      v-for="(event, index) in taskevent" :key="index">
                        <td>{{ event.added_at }}</td>
                        <td>{{ event.event }}</td>
                        <td>{{ event.employee_email }}</td>
                        <td  style="max-width: 25rem; width: 23rem; overflow: scroll;"><pre>{{ event.details ?? '--' }}</pre></td>
                      </tr>
                    </mdb-tbl-body>
            </mdb-tbl>
          </mdb-col>
      </mdb-row>
      </form>
    </section>
    <task-modal
        :selectedTask="selectedTask"
        @close="selectedTask = null"
        :editable="true"
        :takeable="false"
        @save="saveTaskDetails"
        @shown="showIndex = null"
      />
      <mdb-modal v-if="chooseEmployee" :show="chooseEmployee" :scrollable="true" @close="chooseEmployee = false" success size="lg">
      <mdb-modal-header :close="false">
        <mdb-modal-title>Select a Employee</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <div v-if="selectedTaskInfo.employee">
          <a>Assigned Employee: </a>
          <strong >{{selectedTaskInfo.employee.firstname + ' ' + selectedTaskInfo.employee.lastname}}</strong>
          <h5 class="pt-3">Assign this job to another employee</h5>
        </div>
        <div v-else>
          <h5>Assign this job to another employee</h5>
        </div>
        <mdb-card-body>
            <mdb-btn outline="success" class="float-right" @click="goToEmployeesPage()">New employee</mdb-btn>
            <div class="col-6">
              <mdb-input icon="search" v-model="keyword" />
            </div>
            <mdb-datatable-2 v-model='employees' @selected='selectEmployeeTmp($event)' selectable :searching="keyword" />
          </mdb-card-body>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn outline="info" @click="chooseEmployee=false">Cancel</mdb-btn>
        <mdb-btn color="primary" v-on:click="savenewemployee">Save</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <mdb-modal :show="showConfirmCancelOrder" @close="showConfirmCancelOrder=false" danger>
      <mdb-modal-header>
        <mdb-modal-title class="white-text">Are you sure you want to cancel this order?</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <h3 style="font-size: 1.4rem;">You won't be able to revert this!</h3>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn color="danger" v-on:click="cancelOrder()">OK</mdb-btn>
        <mdb-btn outline="elegant" v-on:click="showConfirmCancelOrder=false">Cancel</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <mdb-modal :show="showDuplicateTaskQueueModal" @close="showDuplicateTaskQueueModal=false" success>
      <mdb-modal-header>
        <mdb-modal-title class="white-text">Please select the project where you want to duplicate this task queue</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <label for="projectNames">Projects</label>
        <select
          class="browser-default custom-select"
          id="projectNames"
          v-model="name"
          >
          <option v-for="project in projectList" :key="project.projectid" :value="project.projectid" :v-bind="project.name" style="font-size: 22px;">{{project.name}}</option>
        </select>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn color="success" v-on:click="duplicateTaskQueue()">OK</mdb-btn>
        <mdb-btn outline="elegant" v-on:click="showDuplicateTaskQueueModal=false">Cancel</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <mdb-modal :show="showConfirmRestartWorkFromTask" @close="showConfirmRestartWorkFromTask=false" warning>
      <mdb-modal-header>
        <mdb-modal-title class="white-text">Are you sure you want to restart work from this task?</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <h3 style="font-size: 1.4rem;">You won't be able to revert this!</h3>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn color="warning" v-on:click="restartTaskQueueFromTask()">OK</mdb-btn>
        <mdb-btn outline="elegant" v-on:click="showConfirmRestartWorkFromTask=false">Cancel</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </mdb-container>
</template>

<script>
import {
  mdbAlert,
  mdbBadge,
  mdbBtn,
  mdbCard,
  mdbCol,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
  mdbIcon,
  mdbCardHeader,
  mdbCardBody,
  mdbRow,
  mdbInput,
  mdbContainer,
  mdbModal,
  mdbSwitch,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
  mdbListGroup,
  mdbListGroupItem,
  mdbSpinner,
  mdbDatatable2,
} from 'mdbvue';
import Apicall from '@/libs/Apicall';
import TaskModal from '@/components/TaskModal.vue';
import DeliveryContentViewer from '@/components/DeliveryContentViewer.vue';
import InstructionsBox from '@/components/InstructionsBox.vue';

export default {
  components: {
    mdbAlert,
    InstructionsBox,
    DeliveryContentViewer,
    mdbBadge,
    mdbBtn,
    mdbCard,
    mdbCol,
    mdbContainer,
    mdbSpinner,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbCardHeader,
    mdbCardBody,
    mdbIcon,
    mdbInput,
    mdbRow,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbListGroup,
    mdbListGroupItem,
    mdbDatatable2,
    TaskModal,
    mdbSwitch,
  },
  props: {
    submitError: {
      required: false,
      default: null,
    },
    taskqueueId: {
      required: false,
      default: null,
    },
    submitting: {
      required: true,
      default: false,
    },
    reload: {
      required: true,
      default: false,
    },
  },
  data() {
    return {
      name: '',
      error: null,
      openModal: false,
      isButtonVisible: true,
      textareaWrongTask: null,
      taskScheduleEditableInfo: null,
      loading: false,
      stripeInvoice: '',
      taskqueue: null,
      taskqueueName: '',
      project: null,
      tasks: [],
      projectList: [],
      taskevent: null,
      taskSchedule: null,
      effortRatio: null,
      sendBackDate: null,
      selectedEmployee: null,
      sendBackTime: null,
      showIndex: null,
      selectedTask: null,
      fileUploadTasks: null,
      selectedTaskInfo: null,
      duplicateProjectId: null,
      openTaskScheduleModal: false,
      showDuplicateTaskQueueModal: false,
      billingUnitQuantity: 0,
      taskIsSelected: false,
      taskInfoIndex: null,
      tasktodisplayinfos: null,
      loadingClientReview: false,
      chooseEmployee: false,
      timer: null,
      sendBackDateAndTime: null,
      keyword: '',
      processing: false, // prevent double click button
      newEmployeeForCurrentJob: null,
      internalNotesInputStatus: '',
      oldTaskQueueIsRush: null,
      oldTaskQueueIsToFollow: null,
      showConfirmCancelOrder: false,
      showConfirmRestartWorkFromTask: false,
      newTaskQueue: null,
      taskClickedToRestartWork: [],
      oldInternalNotes: '',
      oldTaskCanBeDispatched: [],
      employees: {
        columns: [
          {
            label: 'First name',
            field: 'first_name',
            sort: true,
          },
          {
            label: 'Last name',
            field: 'last_name',
            sort: true,
          },
          {
            label: 'Email',
            field: 'email',
            sort: true,
          },
          {
            label: 'Current Tasks',
            field: 'nbofcurrenttasks',
            sort: true,
          },
        ],
        rows: [],
      },
    };
  },
  mounted() {
    this.getProjectInfos();
    this.getEffortRatio();
    this.getEmployees();
    if (!this.taskqueueId) {
      this.taskqueue = {
        taskqueueid: null,
        billingunitquantity: 0,
        projectid: this.duplicateProjectId,
        stripeinvoice: '',
      };

      this.tasks = [];
    } else {
      this.getTaskQueueInfo(this.taskqueueId);
    }
  },
  computed: {
    computedSelectedInstructions() {
        // equivalent à nl2br
        if (!this.selectedTaskInfo.instructions) {
          return '';
        }
        return this.selectedTaskInfo.instructions.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br />$2')
          .replace(
            /((http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-]))/,
            '<a href="$1">$1</a>',
          );
    },
  },

  methods: {
    setSelectedTask(task, index) {
      this.selectedTask = task;
      this.selectedTaskInfo = task;

      this.showIndex = index;
    },
    getTaskScheduleInfo(taskSchedule, index) {
      this.taskScheduleEditableInfo = taskSchedule;

      if (this.taskScheduleEditableInfo) {
        const [date, time] = this.taskScheduleEditableInfo.sendback_date.split(' ');

        this.sendBackDate = date;
        this.sendBackTime = time;
        this.openTaskScheduleModal = true;
      }
    },
    editTaskSchedule(taskScheduleId) {
      const apiCall = new Apicall();
      if (this.sendBackDate && this.sendBackTime) {
        this.convertSendBackDate();
        const now = new Date();
        const sendBackDatetime = new Date(this.sendBackDateAndTime);
        if (sendBackDatetime > now) {
          apiCall
            .call('POST', 'api/task/edit-task-schedule', {
              taskScheduleId,
              sendBackDateAndTime: this.sendBackDateAndTime,
            })
            .then((response) => {
              if (response.status === 'OK') {
                this.openTaskScheduleModal = false;
                this.getTaskSchedule(this.selectedTaskInfo.taskid);
                this.notificationSusscess('Task Schedule has been successfuly modified');
              } else {
                this.error = response.msg;
              }
            });
        } else {
          this.notificationError('You must add a valid date');
        }
      }
    },
    convertSendBackDate() {
      this.sendBackDateAndTime = `${this.sendBackDate} ${this.sendBackTime}`;
    },
    setSelectedTaskInfo(task, index) {
      this.taskInfoIndex = index;
      this.selectedTaskInfo = this.tasks[index];
      this.isButtonVisible = true;
      this.getTaskEvent(task.taskid);
      this.getTaskSchedule(task.taskid);
      this.getFileUploadTask(task.taskid);
      this.loadingClientReview = true;
      if (task.type === 'CLIENT-REVIEW') {
        this.getTaskInfos(task.taskid);
      } else {
        this.getEditByCustomerInfos(task.taskid);
      }
    },
    getTaskInfos(taskId) {
      this.loadingClientReview = true;
      const apiCall = new Apicall();
      apiCall
        .call('POST', 'api/task/infos', { taskid: taskId })
        .then((response) => {
          if (response.status === 'OK') {
            this.loadingClientReview = false;
            this.tasktodisplayinfos = response.data;
          } else {
            this.loadingClientReview = false;
            this.error = response.msg;
          }
      });
    },
    getEditByCustomerInfos(taskId) {
      this.loadingClientReview = true;
      const apiCall = new Apicall();
      apiCall
        .call('POST', 'api/task/customer-edit-infos', { taskId })
        .then((response) => {
          if (response.status === 'OK') {
            this.loadingClientReview = false;
            this.tasktodisplayinfos = response.data;
          } else {
            this.loadingClientReview = false;
            this.error = response.msg;
          }
      });
    },
    getEffortRatio() {
      const apiCall = new Apicall();

      apiCall
        .call('GET', 'api/task/effort-ratio', null)
        .then((response) => {
          if (response.status === 'OK') {
            this.effortRatio = response.data.effortratio;
          } else {
            this.error = response.msg;
          }
        });
    },
    getTaskSchedule(taskId) {
      const apiCall = new Apicall();

      apiCall
        .call('POST', 'api/taskschedule/task-schedule-from-task-id', { taskId })
        .then((response) => {
          if (response.status === 'OK') {
            this.taskSchedule = response.data;
          } else {
            this.error = response.msg;
          }
        });
    },
    getFileUploadTask(taskId) {
      const apiCall = new Apicall();

      apiCall
        .call('POST', 'api/task/file-upload-task-from-task-id', { taskId })
        .then((response) => {
          if (response.status === 'OK') {
            this.fileUploadTasks = response.data;
          } else {
            this.error = response.msg;
          }
        });
    },
    getTaskEvent(_taskid) {
      const apiCall = new Apicall();

      apiCall
        .call('POST', 'api/taskevent/task-event', { taskid: _taskid })
        .then((response) => {
          if (response.status === 'OK') {
            this.taskevent = response.data;
          } else {
            this.error = response.msg;
          }
        });
    },
    getProjectInfos() {
      const apiCall = new Apicall();

      apiCall
        .call('GET', 'api/project/get-all', null)
        .then((response) => {
          if (response.status === 'OK') {
            this.projectList = response.data.projectinfos;
          } else {
            this.error = response.msg;
          }
        });
    },
    restartTaskQueueFromTask() {
      this.showConfirmRestartWorkFromTask = false;
      const apiCall = new Apicall();
      apiCall
        .call(
          'POST',
          'api/task/restart-task-queue-from-task',
          { taskqueueId: this.taskqueueId, taskId: this.taskClickedToRestartWork.taskid },
          ).then((response) => {
          if (response.status === 'OK') {
            this.notificationSusscess(`Taskqueue has been restarted from task: ${this.taskClickedToRestartWork.name}`);
          } else {
            this.notificationError(response.msg);
          }
        });
    },
    modifyTaskClickedToRestart(task) {
      this.taskClickedToRestartWork = task;
    },
    getAllTaskQueues(projectId) {
      this.loading = true;
      const apiCall = new Apicall();
      apiCall
        .call(
          'POST',
          'api/task/all-task-queues-project?include-deleted=1',
          { projectid: projectId },
          ).then((response) => {
          this.loading = false;

          if (response.status === 'OK') {
            this.taskQueues = response.data.taskqueueinfos;
          } else {
            this.error = response.msg;
          }
        });
    },
    uploadDeliverablesExternally(taskId) {
      const apiCall = new Apicall();

      apiCall.call('POST', 'api/task/upload-deliverables-externally', { taskId })
        .then((response) => {
          if (response.status === 'OK') {
            this.notificationSusscess('Task deliverables set to be uploaded');
          } else {
            this.notificationError(response.msg);
          }
        });
    },
    duplicateTaskQueue() {
      const project = document.getElementById('projectNames');
      const { value } = project;
      const apiCall = new Apicall();

      if (value) {
        this.showDuplicateTaskQueueModal = false;
        apiCall.call(
          'POST',
          'api/task/duplicate-task-queue-on-selected-project',
          { taskqueueid: this.taskqueueId, projectid: value },
        ).then((response) => {
          if (response.status === 'OK') {
              this.getAllTaskQueues(value);
              this.newTaskQueue = response.data.taskqueueinfos.taskqueueid;
              this.notificationSusscess(`Task queue duplicated. New task queue ID: ${response.data.taskqueueinfos.taskqueueid}`);
          } else {
              this.notificationError(response.msg);
          }
        });
      } else {
        this.notificationError('Please, select a project first');
      }
    },
    getTaskQueueInfo(taskqueueId) {
      this.loading = true;

      const apiCall = new Apicall();

      apiCall
        .call(
          'GET',
          `api/task/get-queue-info?taskqueueid=${taskqueueId}`,
          null,
        )
        .then((response) => {
          this.loading = false;

          if (response.status === 'OK') {
            this.taskqueue = response.data.taskqueueinfos;
            this.stripeInvoice = this.taskqueue.stripeinvoice;
            this.taskqueueName = this.taskqueue.queuename;
            this.billingUnitQuantity = this.taskqueue.billingunitquantity;
            this.tasks = response.data.taskinfos;
            this.project = response.data.projectinfos;
            this.duplicateProjectId = this.project.projectid;
            if (!this.selectedTaskInfo) {
              [this.selectedTaskInfo] = this.tasks;
              this.getTaskEvent(this.selectedTaskInfo.taskid);
              this.getTaskSchedule(this.selectedTaskInfo.taskid);
              this.getFileUploadTask(this.selectedTaskInfo.taskid);
              this.getTaskInfos(this.selectedTaskInfo.taskid);
            }
            if (this.taskqueue.isrush === 'Yes') {
              this.taskqueue.isrush = true;
            } else {
              this.taskqueue.isrush = false;
            }
            if (this.selectedTaskInfo.canbedispatched === 'Yes') {
              this.selectedTaskInfo.canbedispatched = true;
            } else if (this.selectedTaskInfo.canbedispatched === 'No') {
              this.selectedTaskInfo.canbedispatched = false;
            }
            this.oldTaskQueueIsRush = this.taskqueue.isrush;
            this.oldTaskQueueIsToFollow = this.taskqueue.istofollow;
            this.tasks.forEach((element, index) => {
              if (this.tasks[index].canbedispatched === 'Yes') {
              this.tasks[index].canbedispatched = true;
            } else if (this.tasks[index].canbedispatched === 'No') {
              this.tasks[index].canbedispatched = false;
            }
              this.oldTaskCanBeDispatched[element.taskid] = { taskid: element.taskid, canbedispatched: element.canbedispatched };
            });

            if (this.$route.params.taskId) {
              const taskToSelect = this.tasks.find((task) => task.taskid === this.$route.params.taskId);
              const taskIndex = this.tasks.findIndex((task) => task.taskid === this.$route.params.taskId);
              this.setSelectedTaskInfo(taskToSelect, taskIndex);
            }
          } else {
            this.error = response.msg;
          }
        });
    },
    moveTask(oldIndex, newIndex) {
      const task = this.tasks[oldIndex];

      this.tasks.splice(oldIndex, 1);
      this.tasks.splice(newIndex, 0, task);
      this.submit();
    },
    deleteTask(index) {
      this.tasks.splice(index, 1);
      this.submit();
    },
    addTask() {
      const task = {
        taskid: null,
        name: 'New task',
        type: null,
        remununit: 'MIN',
        remununitnb: 0,
        remununitrate: 0.09,
        instructions: null,
        isassigned: false,
        iscompleted: false,
        needsfilestocomplete: false,
        taskqueueid: this.taskqueueId ? this.taskqueueId : null,
      };

      this.tasks.push(task);

      this.showIndex = null;
    },

    goToCustomerDetails(id) {
      this.$router.push({ name: 'AdminCustomers', params: { customer: id } });
    },

    goToEmployeeDetails(id) {
      this.$router.push({ name: 'AdminEmployees', params: { employee: id } });
    },
    goToEmployeesPage() {
      this.$router.push({ name: 'AdminEmployees' });
    },

    saveTaskDetails(newDetails) {
      if (this.selectedTask) {
        this.selectedTask.type = newDetails.type;
        this.selectedTask.instructions = newDetails.instructions;
        this.selectedTask.deliverycontent = newDetails.deliverycontent;
        this.selectedTask.name = newDetails.name;
        this.selectedTask.remununit = newDetails.remununit;
        this.selectedTask.remununitrate = newDetails.remununitrate;
        this.selectedTask.remununitnb = newDetails.remununitnb;
        this.selectedTask.skills = newDetails.skills;
        this.recalculateTotal();
        if (this.selectedTaskInfo) {
          this.tasks[this.taskInfoIndex] = this.selectedTaskInfo;
        }
        this.selectedTask = null;
        this.submit();
      }
    },
    sendBackToEmployee(taskid) {
      let deliverablesArray = [];
      const textareas = [];
      if (this.selectedTaskInfo.deliverycontent && this.selectedTaskInfo.deliverycontent.length > 0) {
        for (let i = 0; i < this.selectedTaskInfo.deliverycontent.length; i += 1) {
          const deliverableIndex = i;
          const textareaValue = document.getElementById(`textarea_deliverable_${i}`).value.trim();
          const textarea = { deliverableIndex, textareaValue };
          if (textarea.textareaValue !== '') {
            textareas.push(textarea);
          }
        }
        deliverablesArray = this.selectedTaskInfo.deliverycontent.filter((deliverable, index) => {
          let item = [];
          textareas.forEach((textarea) => {
            if (index === textarea.deliverableIndex) {
              item = deliverable;
              item.index = index;
              item.textarea = textarea.textareaValue;
            }
          });
          return item?.textarea;
        });
      } else {
        const textareaValue = document.getElementById('textarea_deliverable').value;
        if (textareaValue !== '') {
          deliverablesArray.push({ textarea: textareaValue });
        }
      }
      const apiCall = new Apicall();
      apiCall.call(
        'POST',
        'api/task/send-back-to-employee', { taskid, deliverablesArray },
      ).then((response) => {
        if (response.status === 'OK') {
          this.notificationSusscess('Email sent to Employee');
          this.isButtonVisible = false;
          this.selectedTaskInfo.completedat = null;
          this.openModal = false;
        } else {
          this.notificationError(response.msg);
          this.openModal = false;
        }
      });
    },

    recalculateTotal() {
      let total = 0;

      this.tasks.forEach((task) => {
        if (task) {
          total += parseInt(task.remununitnb, 10);
        }
      });

      this.billingUnitQuantity = Math.ceil(total / this.effortRatio);
    },

    copyQueueToProject(projectid) {
      const apiCall = new Apicall();

      apiCall.call(
        'POST',
        'api/task/duplicate-queue',
        { taskqueueid: this.taskqueueId, projectid },
      ).then((response) => {
        if (response.status === 'OK') {
          this.$emit('reload', response.data.taskqueueinfos.taskqueueid);
        } else {
            this.error = response.msg;
        }
      });
    },
    cancelOrder() {
      const apiCall = new Apicall();

      apiCall.call(
        'POST',
        'api/task/delete-queue',
        { taskqueueid: this.taskqueueId },
      ).then((response) => {
        if (response.status === 'OK') {
            this.$router.push({ name: 'Admin' });
        } else {
            this.error = response.msg;
        }
      });
    },

    copyToClipboard(copiedText) {
      const input = document.createElement('textarea');
      input.innerHTML = copiedText;
      document.body.appendChild(input);
      input.select();
      const result = document.execCommand('copy');
      document.body.removeChild(input);
    },

    nl2br(str) {
      if (str) {
        return str.replace(
          /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
          '$1<br />$2',
        );
      }
      return '';
    },

    submit() {
      this.taskqueue.queuename = this.taskqueueName;
      this.taskqueue.projectid = this.duplicateProjectId;
      this.taskqueue.stripeinvoice = this.stripeInvoice;

      this.$emit(
        'submit',
        this.taskqueue,
        this.tasks,
      );
    },

    autoSaveInternalNotes() {
      this.internalNotesInputStatus = 'Typing...';
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        if (this.oldInternalNotes !== this.taskqueue.internalnotes) {
          this.submit();
          this.oldInternalNotes = this.taskqueue.internalnotes;
        }
      }, 1000);
    },

    autoSaveTaskqueueName() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
        return;
      }
      this.timer = setTimeout(() => {
          this.submit();
      }, 1000);
    },

    autoSaveChangeRush() {
      if (this.oldTaskQueueIsRush !== this.taskqueue.isrush) {
        this.submit();
        this.oldTaskQueueIsRush = this.taskqueue.isrush;
      }
    },
    autoSaveToFollow() {
      if (this.oldTaskQueueIsToFollow !== this.taskqueue.istofollow) {
        this.submit();
        this.oldTaskQueueIsToFollow = this.taskqueue.istofollow;
      }
    },

    autoSaveCanBeDispatched() {
      const apiCall = new Apicall();
      if (this.oldTaskCanBeDispatched[this.selectedTaskInfo.taskid].canbedispatched !== this.selectedTaskInfo.canbedispatched) {
        apiCall.call(
          'POST',
          'api/task/change-canbedispatched',
          {
            taskid: this.selectedTaskInfo.taskid,
            canbedispatched: this.selectedTaskInfo.canbedispatched,
          },
        ).then(() => {
          this.getTaskQueueInfo(this.taskqueueId);
        });
        // this.oldTaskCanBeDispatched[this.selectedTaskInfo.taskid].canbedispatched = this.selectedTaskInfo.canbedispatched;
      }
    },

    autoSaveOverrideQuantity() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      if (this.submitting) {
        return;
      }
      this.timer = setTimeout(() => {
          this.taskqueue.billingunitquantity = this.billingUnitQuantity;
          this.submit();
      }, 1000);
    },

    notificationSusscess(_messsage) {
        this.$notify.success({ message: _messsage, position: 'top right', timeOut: 5000 });
    },
    notificationError(_messsage) {
        this.$notify.error({ message: _messsage, position: 'top right', timeOut: 5000 });
    },

    removeEmployeeFromTask(_taskid) {
      const apiCall = new Apicall();
      apiCall.call(
        'POST',
        'api/task/remove-employee',
        { taskid: _taskid },
      ).then((response) => {
        if (response.status === 'OK') {
            this.selectedTaskInfo.employee = null;
            this.getTaskQueueInfo(this.taskqueueId);
            this.notificationSusscess('Unassign success!');
        } else {
            this.error = response.msg;
            this.notificationError(response.msg);
        }
      });
    },
    markAsCompleted(_taskid) {
      const apiCall = new Apicall();
      apiCall.call(
        'POST',
        'api/task/mark-as-completed',
        { taskid: _taskid },
      ).then((response) => {
        if (response.status === 'OK') {
            this.selectedTaskInfo.completedat = response.data;
            this.getTaskQueueInfo(this.taskqueueId);
            this.notificationSusscess('Marked as completed!');
        } else {
            this.error = response.msg;
            this.notificationError(response.msg);
        }
      });
    },

    showchooseEmployee() {
      this.chooseEmployee = true;
    },

    getEmployees() {
        const api = new Apicall();
        this.newEmployeeForCurrentJob = null;
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
        this.timer = setTimeout(() => {
            this.processing = true;
            this.employees.rows = [];
            api.call('GET', 'api/adminemployee/all-employees-with-nb-tasks').then((response) => {
              this.processing = false;
              if (response.status === 'OK') {
                  this.employees.rows = response.data.employees;
              }
          });
        }, 400);
    },

    selectEmployeeTmp(employee) {
        this.newEmployeeForCurrentJob = employee.id;
    },

    savenewemployee() {
        const api = new Apicall();
        api.call(
          'POST',
          'api/task/set-new-employee-for-task',
          {
            taskid: this.selectedTaskInfo.taskid,
            employeeid: this.newEmployeeForCurrentJob,
          },
        ).then((response) => {
          if (response.status === 'OK') {
            this.chooseEmployee = false;
            this.newEmployeeForCurrentJob = null;
            this.getTaskQueueInfo(this.taskqueueId);
            this.selectedTaskInfo.employee = response.data.employee;
            this.notificationSusscess(`Task newly assigned to ${this.selectedTaskInfo.employee.firstname} ${this.selectedTaskInfo.employee.lastname}`);
          } else if (response.status === 'ERROR') {
            this.notificationError(response.msg);
          }
        }).catch((response) => {
            this.notificationError(response.msg);
        });
    },
    goToProjectPage(taskqueue) {
      this.$router.push({ name: 'AdminCustomerProject', params: { customerid: taskqueue.project.client.id, projectid: taskqueue.project.projectid } });
    },
  },

  watch: {
    submitError(newVal) {
      this.error = newVal;
    },

    taskqueueId(newVal) {
      if (newVal) {
        this.getTaskQueueInfo(newVal);
      }
    },

    reload(newVal) {
      this.taskqueue = null;
      this.getTaskQueueInfo(this.taskqueueId);
    },
    submitting(newVal) {
      if (this.internalNotesInputStatus === 'Typing...' && newVal === false) {
        this.internalNotesInputStatus = 'Saved';
      }
    },
  },
};
</script>

<style scoped>
  tbody.employees tr:hover, tbody.employees tr.active{
    background-color: #68ffca;
  }
  tbody.lesitems tr:hover, tbody.lesitems tr.active{
    background-color: #68ffca;
  }
  .blackcolor{
    color: #000;
  }
  .table-size {
      max-width: 400px;
      margin: 0 auto;
  }

  @media (min-width:768px){
    .table-size {
      max-width: 500px;
      margin: 0 auto;
    }
  }
  .deliverable-info {
    display: flex;
  }
  .danger-info {
    font-weight: bold;
    color: #DC3545;
  }
  .dropdown-menu {
    max-width: 350px;
    overflow-x: auto;
    left: -150px;
  }
  .dropdown-menu .dropdown-item:hover {
    pointer-events: none !important;
  }
  .lightgraycolor{
    color: #ababab;
  }
  .btn.btn-sm.task-action-btn {
    padding: 0.3rem 0.4rem;
    font-size: .64rem;
  }
</style>
