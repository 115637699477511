<template>
    <mdb-modal v-show="value" @close="$emit('input', false)">
      <mdb-modal-header  color="grey lighten-4" class="d-flex align-items-center justify-content-between" textColor="grey darken-4">
        <b class="ml-3 mb-0">Sign up to Soustitreur to take this task!</b>
      </mdb-modal-header>
      <form @submit="onSubmit" @change="error = null">
        <mdb-modal-body>
          <mdb-alert v-if="error" color="danger" class="mt-1" >{{ error }}</mdb-alert>
          <mdb-container>
            <input name="jobid" type="hidden" v-model="jobId" />
            <input autocomplete="false" name="hidden" type="text" style="display:none;" />
            <mdb-row>
              <mdb-col class="text-center">
              </mdb-col>
            </mdb-row>
            <mdb-row>
              <mdb-col>
                <mdb-input label="Email" read-only v-model="email" required />
              </mdb-col>
            </mdb-row>
            <mdb-row>
              <mdb-col>
                <mdb-input label="First Name" name="firstname" v-model="firstName" required aria-autocomplete="false"/>
              </mdb-col>
            </mdb-row>
            <mdb-row>
              <mdb-col>
                <mdb-input label="Last Name" name="lastname" v-model="lastName" required aria-autocomplete="false"/>
              </mdb-col>
            </mdb-row>
            <mdb-row>
              <mdb-col>
                <mdb-input label="Password" type="password" v-model="password" required />
              </mdb-col>
            </mdb-row>
          </mdb-container>
        </mdb-modal-body>
        <mdb-modal-footer>
          <mdb-btn color="success" type="submit" :disabled="loading">
            <div class="spinner-border mr-1 text-light spinner-border-sm" role="status" v-if="loading">
              <span class="sr-only">Loading...</span>
            </div>
            Sign up!
          </mdb-btn>
        </mdb-modal-footer>
      </form>
    </mdb-modal>
  </template>

  <script>
  import {
   mdbAlert, mdbModal, mdbModalHeader, mdbModalBody, mdbContainer, mdbCol, mdbRow, mdbInput, mdbBtn, mdbModalFooter,
  } from 'mdbvue';
  import Apicall from '@/libs/Apicall';

  export default {
    props: {
      value: {
        type: Boolean,
        required: true,
      },
      email: {
        type: String,
        required: true,
      },
      jobId: {
        type: String,
        required: false,
      },
    },
    components: {
      mdbAlert,
      mdbBtn,
      mdbCol,
      mdbContainer,
      mdbInput,
      mdbModal,
      mdbModalBody,
      mdbModalFooter,
      mdbModalHeader,
      mdbRow,
    },
    data() {
      return {
        firstName: '',
        lastName: '',
        password: '',
        error: null,
        loading: false,
      };
    },
    methods: {
      onSubmit(event) {
        event.preventDefault();
        const api = new Apicall();
        this.loading = true;
        api.call('POST', 'api/soustitreur/signup', {
          firstname: this.firstName, lastname: this.lastName, password: this.password, jobid: this.jobId,
        }).then((response) => {
          if (response.status === 'OK') {
            this.$emit('success', response.data.loginurl);
            // Close modal
            this.$emit('input', false);
          } else {
            this.error = response.msg;
          }
        }).finally(() => {
          this.loading = false;
          this.firstName = '';
          this.lastName = '';
          this.password = '';
        });
      },
    },
  };
  </script>

  <style>

  </style>
