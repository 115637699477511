<template>
  <mdb-container fluid>
    <Header></Header>
    <Admintopbar :breadcrumb='breadcrumb' />
    <section class="mt-3">
      <mdb-row>
        <mdb-col md="12">
    <mdb-card>
      <mdb-card-header color="mdb-color darken-1">
        Ledger
      </mdb-card-header>
      <mdb-card-body>
        <mdb-row v-if='error' class='justify-content-around pt-4'>
          <mdb-col md='8'>
            <mdb-alert color='danger' >{{error}}</mdb-alert>
          </mdb-col>
        </mdb-row>
        <mdb-row>
          <mdb-col>
            <ledger-table v-on:error="(errorMsg) => error = errorMsg"></ledger-table>
          </mdb-col>
        </mdb-row>
      </mdb-card-body>
    </mdb-card>
    </mdb-col>
    </mdb-row>
    </section>
  </mdb-container>
</template>

<script>
import {
  mdbRow, mdbCol, mdbAlert, mdbContainer, mdbCard, mdbCardHeader, mdbCardBody,
} from 'mdbvue';
import LedgerTable from '@/components/LedgerTable.vue';
import Header from '@/components/Header.vue';
import Admintopbar from '@/components/Admintopbar.vue';

export default {
  components: {
    Admintopbar,
    Header,
    LedgerTable,
    mdbAlert,
    mdbCol,
    mdbContainer,
    mdbRow,
    mdbCard,
    mdbCardHeader,
    mdbCardBody,
  },

  data() {
    return {
      error: null,
      breadcrumb: [
        {
          title: 'Ledger',
          href: '/admin/ledgers',
        },
        {
          title: 'List',
          href: null,
        },
      ],
    };
  },

};
</script>

<style>

</style>
