<template>
  <mdb-container fluid>
    <Header></Header>
    <section class="mt-3">
      <mdb-row v-if="errormsg">
        <mdb-col xl="12" md="12" class="mb-4">
          <mdb-alert color="danger" class="mt-3">
            {{ errormsg }}
          </mdb-alert>
        </mdb-col>
      </mdb-row>
      <mdb-row v-if="successmsg">
        <mdb-col xl="12" md="12" class="mb-4">
          <mdb-alert color="success" class="mt-3">
            {{ successmsg }}
          </mdb-alert>
        </mdb-col>
      </mdb-row>
      <mdb-row class="justify-content-md-center">
        <mdb-col xl="6" md="6" >
          <mdb-card >
            <mdb-card-header color="blue accent-3">User Details</mdb-card-header>
            <mdb-card-body>
              <form v-bind:class="{'was-validated': success===false}" novalidate autocomplete="off" @submit="checkForm" @input="changeForm" @click="changeForm">
                <mdb-row>
                  <mdb-col md="6">
                    <mdb-input required label="First Name" v-model="employee.first_name"/>
                  </mdb-col>
                  <mdb-col md="6">
                    <mdb-input required label="Last Name" v-model="employee.last_name"/>
                  </mdb-col>
                </mdb-row>
                <small class="form-text">First Name and Last Name must be identical to your bank account name for bank transfers</small>
                <mdb-row>
                  <mdb-col md="6">
                    <mdb-input disabled required label="Nickname" v-model="employee.nickname" />
                  </mdb-col>
                  <mdb-col md="6">
                    <mdb-input
                      required
                      :customValidation="validation.email.validated"
                      :isValid="validation.email.valid"
                      @change="validate('email')"
                      type="email" label="Email" v-model="employee.email" />
                  </mdb-col>
                </mdb-row>
                <mdb-row>
                  <mdb-col md="6">
                    <mdb-input label="Mobile" v-model="employee.cell" />
                  </mdb-col>
                  <mdb-col md="6">
                    <mdb-input label="Password" name="new_password" v-model="newPassword" :type="!seePassword ? 'password' : 'text'" autocomplete="off" :aria-autocomplete="false">
                      <button class="input-group-text md-addon" slot="append" @click="seePassword = !seePassword">
                        <mdb-icon icon="eye-slash" />
                      </button>
                    </mdb-input>
                  </mdb-col>
                </mdb-row>
                <mdb-row>
                  <mdb-col md="6">
                    <h4>How to pay</h4>
                  </mdb-col>
                </mdb-row>
                <mdb-row>
                  <mdb-col md="6">
                    <mdb-select required validation ref="validationRegion" invalidFeedback="Please choose region." label="Region" v-model="regionOptions" placeholder="Select a region" />
                  </mdb-col>
                  <mdb-col md="6">
                    <mdb-select required validation ref="validationPaymentMethod" invalidFeedback="Please choose payment method." label="Payment method" v-model="paymentOptions"/>
                    <small v-if="employee.howtopay != null && (employee.howtopay.toLowerCase().includes('paypal') || employee.howtopay.toLowerCase().includes('interac'))" class="form-text">
                      Each weekend, if your balance is greater than the minimum, we will send the money to: {{employee.email}}
                    </small>
                  </mdb-col>
                </mdb-row>
                <div v-if="employee.howtopay != null && employee.howtopay.toLowerCase().includes('wire')">
                  <mdb-row>
                    <mdb-col md="6">
                      <mdb-input required type="text" label="Iban (bank account number)" v-model="iban" />
                    </mdb-col>
                    <mdb-col md="6">
                      <mdb-input required type="text" label="Swift code" v-model="swift" />
                    </mdb-col>
                  </mdb-row>
                  <mdb-row v-if="region === 'Brazil'">
                    <mdb-col md="6">
                      <mdb-input required type="text" label="Bank Code" v-model="bankcode" />
                    </mdb-col>
                    <mdb-col md="6">
                      <mdb-input required type="text" label="Branch Code" v-model="branchcode" />
                    </mdb-col>
                  </mdb-row>
                  <mdb-row>
                    <mdb-col md="6">
                      <mdb-input required type="text" label="Bank name" v-model="bankname" />
                    </mdb-col>
                    <mdb-col md="6">
                      <mdb-input
                      required
                      :customValidation="validation.birthday.validated"
                      :isValid="validation.birthday.valid"
                      @change="validate('birthday')"
                      label="Birthday" v-model="birthday" @click.native="$refs.datePicker.open()" />
                      <mdb-date-picker-2
                        ref="datePicker"
                        disable-input
                        disabledFuture
                        label="Birthday"
                        v-model="birthday" />
                    </mdb-col>
                  </mdb-row>
                  <mdb-row>
                    <mdb-col md="6">
                      <mdb-input required type="text" label="Address line 1" v-model="addressline1" />
                    </mdb-col>
                    <mdb-col md="6">
                      <mdb-input required type="text" label="City" v-model="city" />
                    </mdb-col>
                  </mdb-row>
                  <mdb-row>
                    <mdb-col md="6">
                      <mdb-input required type="text" label="Country" v-model="country" />
                    </mdb-col>
                    <mdb-col md="6">
                      <mdb-input v-if="region === 'Brazil'" required type="text" label="National Tax ID" v-model="nationaltaxid" />
                    </mdb-col>
                  </mdb-row>
                  <mdb-row>
                    <mdb-col md="12">
                      <template>
                        <mdb-input type="textarea" label="Note" :rows="2" v-model="note"/>
                      </template>
                    </mdb-col>
                  </mdb-row>
                  <mdb-row>
                    <mdb-col md="12">
                      <template>
                        <mdb-input disabled type="textarea" label="Summary Payment Info" :rows="region === 'Brazil' ? 12 : 10" bg size="md" v-model="employee.notes"/>
                      </template>
                    </mdb-col>
                  </mdb-row>
                </div>
                <mdb-spinner v-if="loading" multicolor size="sm"></mdb-spinner>
                <mdb-btn v-else color="blue accent-3" type="submit" @click.native="validateSelection($refs)">Save</mdb-btn>
              </form>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
      </mdb-row>
    </section>
  </mdb-container>
</template>

<script>
  import Vue from 'vue';
  import {
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbCard,
    mdbCardHeader,
    mdbCardBody,
    mdbBtn,
    mdbInput,
    mdbAlert,
    mdbSelect,
    mdbSpinner,
    mdbIcon,
    mdbDatePicker2,
  } from 'mdbvue';
  import Header from '@/components/Header.vue';

  import Apicall from '@/libs/Apicall';

  export default Vue.extend({
    name: 'Preferences',
    components: {
        Header,
        mdbContainer,
        mdbRow,
        mdbCol,
        mdbCard,
        mdbCardHeader,
        mdbCardBody,
        mdbBtn,
        mdbIcon,
        mdbInput,
        mdbAlert,
        mdbSelect,
        mdbSpinner,
        mdbDatePicker2,
    },
    computed: {
      selectedRegion() {
        const selected = this.regionOptions.find(
          (option) => option.selected,
        );
        if (selected === undefined) return '';
        return selected;
      },
      selectedPaymentMethod() {
        const selected = this.paymentOptions.find(
          (option) => option.selected,
        );
        if (selected === undefined) return '';
        return selected;
      },
    },
    watch: {
      selectedRegion: {
        deep: true,
        handler(newVal) {
          if (newVal) {
            this.region = newVal.value;
            this.generatePaymentMethods(newVal.value);
            this.generateSummary();
          }
        },
      },
      selectedPaymentMethod: {
        deep: true,
        handler(newVal) {
          if (newVal) {
            this.employee.howtopay = newVal.value;
            this.generateSummary();
          }
        },
      },
      iban() {
        this.generateSummary();
      },
      swift() {
        this.generateSummary();
      },
      bankname() {
        this.generateSummary();
      },
      birthday() {
        this.generateSummary();
      },
      addressline1() {
        this.generateSummary();
      },
      city() {
        this.generateSummary();
      },
      country() {
        this.generateSummary();
      },
      note() {
        this.generateSummary();
      },
      bankcode() {
        this.generateSummary();
      },
      branchcode() {
        this.generateSummary();
      },
      nationaltaxid() {
        this.generateSummary();
      },
    },
    data() {
      return {
        employee: {},
        success: null,
        errormsg: null,
        successmsg: null,
        regionOptions: [],
        paymentOptions: [],
        newPassword: null,
        notes: [],
        seePassword: false,
        iban: null,
        swift: null,
        bankname: null,
        birthday: null,
        addressline1: null,
        city: null,
        country: null,
        region: null,
        note: null,
        originalnotes: null,
        validation: {
          email: {
            valid: false,
            validated: false,
          },
          birthday: {
            valid: false,
            validated: false,
          },
        },
        validForm: false,
        bankcode: null,
        branchcode: null,
        nationaltaxid: null,
        loading: false,
      };
    },
    mounted() {
        this.getemployeeBasicInfo();
    },
    methods: {
      getemployeeBasicInfo() {
        const self = this;
        const api = new Apicall();
        api.call('GET', 'api/me/get-infos', null).then((response) => {
          self.regionOptions = [
            { text: 'Europe', value: 'Europe', selected: false },
            { text: 'Madagascar', value: 'Madagascar', selected: false },
            { text: 'Canada', value: 'Canada', selected: false },
            { text: 'Brazil', value: 'Brazil', selected: false },
            { text: 'Rest of the world', value: 'Rest of the world', selected: false },
          ];
          if (response.status === 'OK') {
            self.employee = response.data;
            if (self.employee.notes !== null) {
              self.notes = self.employee.notes.split('\n');
              self.originalnotes = self.notes;
              if (self.notes.length > 0) {
                self.notes.forEach((element) => {
                  if (element.toUpperCase().includes('IBAN:')) {
                    self.iban = element.slice(element.toUpperCase().indexOf('IBAN:') + 'IBAN:'.length, element.length);
                  }
                  if (element.toUpperCase().includes('SWIFT:')) {
                    self.swift = element.slice(element.toUpperCase().indexOf('SWIFT:') + 'SWIFT:'.length, element.length);
                  }
                  if (element.toUpperCase().includes('BANKNAME:') || element.toUpperCase().includes('BANK:')) {
                    if (element.toUpperCase().includes('BANKNAME:')) {
                      self.bankname = element.slice(element.toUpperCase().indexOf('BANKNAME:') + 'BANKNAME:'.length, element.length);
                    }
                    if (element.toUpperCase().includes('BANK:')) {
                      self.bankname = element.slice(element.toUpperCase().indexOf('BANK:') + 'BANK:'.length, element.length);
                    }
                  }
                  if (element.toUpperCase().toUpperCase().includes('BIRTHDATE:')) {
                    self.birthday = element.slice(element.toUpperCase().indexOf('BIRTHDATE:') + 'BIRTHDATE:'.length, element.length);
                  }
                  if (element.toUpperCase().includes('ADDRESSLINE1:')) {
                    if (element.toUpperCase().includes('ADDRESS:')) {
                      self.addressline1 = element.slice(element.toUpperCase().indexOf('ADDRESS:') + 'ADDRESS:'.length, element.length);
                    }
                    if (element.toUpperCase().includes('ADDRESSLINE1:')) {
                      self.addressline1 = element.slice(element.toUpperCase().indexOf('ADDRESSLINE1:') + 'ADDRESSLINE1:'.length, element.length);
                    }
                  }
                  if (element.toUpperCase().includes('CITY:')) {
                    self.city = element.slice(element.toUpperCase().indexOf('CITY:') + 'CITY:'.length, element.length);
                  }
                  if (element.toUpperCase().includes('COUNTRY:')) {
                    self.country = element.slice(element.toUpperCase().indexOf('COUNTRY:') + 'COUNTRY:'.length, element.length);
                  }
                  if (element.toUpperCase().includes('REGION:')) {
                    self.region = element.slice(element.toUpperCase().indexOf('REGION:') + 'REGION:'.length, element.length);
                    self.regionOptions.find((v) => v.value === self.region).selected = true;
                    this.generatePaymentMethods(self.region);
                  }
                  if (element.toUpperCase().toUpperCase().includes('NOTE:')) {
                    self.note = element.slice(element.toUpperCase().indexOf('NOTE:') + 'NOTE:'.length, element.length);
                  }
                  if (element.toUpperCase().toUpperCase().includes('NATIONALTAXID:')) {
                    self.nationaltaxid = element.slice(element.toUpperCase().indexOf('NationalTaxId:') + 'NationalTaxId:'.length, element.length);
                  }
                  if (element.toUpperCase().toUpperCase().includes('BANKCODE:')) {
                    self.bankcode = element.slice(element.toUpperCase().indexOf('BANKCODE:') + 'BANKCODE:'.length, element.length);
                  }
                  if (element.toUpperCase().toUpperCase().includes('BRANCHCODE:')) {
                    self.branchcode = element.slice(element.toUpperCase().indexOf('BRANCHCODE:') + 'BRANCHCODE:'.length, element.length);
                  }
                });
              }
            }
            this.generatePaymentMethods(self.region);
          } else {
            self.error = response.msg;
          }
        });
      },
      generatePaymentMethods(_region) {
        switch (_region) {
          case 'Madagascar':
            this.paymentOptions = [{
              text: 'Emadex (small fee, minimum 10$CAD)', value: 'interac', selected: false, disabled: false,
              }, { text: 'Bank transfer (free, minimum 100$CAD)', value: 'wire', selected: false }, { text: 'Paypal (charges fees, minimum 10$CAD)', value: 'paypal', selected: false }];
            if (_region === null) this.paymentOptions.find((v) => v.value === 'interac').text = 'Interac (free, minimum 10$CAD)';
            break;
          case 'Canada':
            if (this.employee.howtopay != null && this.employee.howtopay.toLowerCase().includes('wire')) {
              this.paymentOptions = [{ text: 'Bank transfer', value: 'wire', selected: true }, { text: 'Interac (free, minimum 10$CAD)', value: 'interac', selected: false }, { text: 'Paypal (free, minimum 10$CAD)', value: 'paypal', selected: false }];
            } else {
              this.paymentOptions = [{ text: 'Interac (free, minimum 10$CAD)', value: 'interac', selected: false }, { text: 'Paypal (free, minimum 10$CAD)', value: 'paypal', selected: false }];
            }
            break;
          case 'Europe':
            if (this.employee.howtopay != null && this.employee.howtopay.toLowerCase().includes('interac')) {
              this.paymentOptions = [{ text: 'Interac', value: 'interac', selected: true }, { text: 'Bank transfer (free, minimum 100$CAD)', value: 'wire', selected: false }, { text: 'Paypal (charges fees, minimum 10$CAD)', value: 'paypal', selected: false }];
            } else {
              this.paymentOptions = [{ text: 'Bank transfer (free, minimum 100$CAD)', value: 'wire', selected: false }, { text: 'Paypal (charges fees, minimum 10$CAD)', value: 'paypal', selected: false }];
            }
            break;
          case 'Brazil':
          case 'Rest of the world':
            if (this.employee.howtopay != null && this.employee.howtopay.toLowerCase().includes('interac')) {
              this.paymentOptions = [{ text: 'Interac', value: 'interac', selected: true }, { text: 'Bank transfer (free, minimum 100$CAD)', value: 'wire', selected: false }, { text: 'Paypal (charges fees, minimum 10$CAD)', value: 'paypal', selected: false }];
            } else {
              this.paymentOptions = [{ text: 'Bank transfer (free, minimum 100$CAD)', value: 'wire', selected: false }, { text: 'Paypal (charges fees, minimum 10$CAD)', value: 'paypal', selected: false }];
            }
            break;
          case null:
            if (this.employee.howtopay !== null) {
              if (this.employee.howtopay.includes('wire')) {
              this.paymentOptions = [{ text: 'Bank transfer', value: 'wire', selected: true }];
              }
              if (this.employee.howtopay.includes('paypal')) {
                this.paymentOptions = [{ text: 'Paypal', value: 'paypal', selected: true }];
              }
              if (this.employee.howtopay.includes('interac')) {
                this.paymentOptions = [{ text: 'Interac', value: 'interac', selected: true }];
              }
            }
            break;
          default:
            break;
        }
        if (this.employee.howtopay !== null) {
          this.selectPaymentMethod(this.employee.howtopay);
        }
      },
      selectPaymentMethod(_paymentMethod) {
        let paymentMethod = '';
        if (_paymentMethod.toLowerCase().includes('wire')) paymentMethod = 'wire';
        if (_paymentMethod.toLowerCase().includes('paypal')) paymentMethod = 'paypal';
        if (_paymentMethod.toLowerCase().includes('interac')) paymentMethod = 'interac';
        if (_paymentMethod) this.paymentOptions.find((v) => v.value === paymentMethod).selected = true;
        if (this.region === 'Canada' && paymentMethod === 'interac') this.paymentOptions.find((v) => v.value === paymentMethod).text = 'Interac (free, minimum 10$CAD)';
        if (this.region === 'Madagascar' && paymentMethod === 'interac') this.paymentOptions.find((v) => v.value === paymentMethod).text = 'Emadex (small fee, minimum 10$CAD)';
      },
      checkForm(event) {
        event.target.classList.add('was-validated');
        event.preventDefault();
        if (this.validForm === true) {
          this.saveinfos();
        }
      },
      saveinfos() {
          const self = this;
          const api = new Apicall();
          this.loading = true;
          api.call('POST', 'api/employee/update-references', { ...self.employee, new_password: this.newPassword }).then((response) => {
              this.loading = false;
              if (response.status === 'OK') {
                self.success = true;
                this.notificationSusscess(response.msg);
                window.scrollTo(0, 0);
                self.errormsg = null;
                this.$store.state.first_name = self.first_name;
                this.$store.state.last_name = self.last_name;
                this.employee = response.data.employee;
              } else if (response.status === 'ERROR') {
                self.success = false;
                self.successmsg = null;
                self.errormsg = response.msg;
                this.notificationError(self.errormsg);
              }
          }).catch((response) => {
            this.loading = false;
            this.success = false;
            self.successmsg = null;
            // this.errormsg = response.data.msg;
            this.notificationError(self.errormsg);
            window.scrollTo(0, 0);
          });
      },
      gotoemployeeslist() {
          this.$router.push({ name: 'employees' });
      },
      loademployeeDetails(_ev) {
          this.$router.push({ name: 'employeedetails', params: { id: _ev } });
      },
      validateSelection($refs) {
        $refs.validationRegion.validate();
        $refs.validationPaymentMethod.validate();
        this.validate('email');
        if (this.employee.howtopay.toLowerCase().includes('wire')) {
          this.validate('birthday');
        }
        if (this.employee.first_name && this.employee.last_name && this.validation.email.valid && $refs.validationRegion.valid && $refs.validationPaymentMethod.valid) {
          if ($refs.validationPaymentMethod.inputValue.toLowerCase().includes('wire')) {
            if (this.region && this.iban && this.swift && this.bankname && this.validation.birthday.valid && this.addressline1 && this.city && this.country) {
              this.validForm = true;
            } else {
              this.validForm = false;
            }
          } else {
            this.validForm = true;
          }
        } else {
          this.validForm = false;
        }
      },
      validate(key) {
        if (key === 'email') {
          if (this.validateEmail(this.employee.email)) {
            this.validation[key].valid = true;
          } else {
            this.validation[key].valid = false;
          }
          this.validation[key].validated = true;
        }
        if (key === 'birthday') {
          if (this.isValidDate(this.birthday)) {
            this.validation[key].valid = true;
          } else {
            this.validation[key].valid = false;
          }
          this.validation[key].validated = true;
        }
      },
      validateEmail(_email) {
        return String(_email)
          .toLowerCase()
          .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
      },
      isValidDate(dateString) {
        const regEx = /^\d{4}-\d{2}-\d{2}$/;
        if (!dateString.match(regEx)) return false; // Invalid format
        const d = new Date(dateString);
        const dNum = d.getTime();
        if (!dNum && dNum !== 0) return false; // NaN value, Invalid date
        return d.toISOString().slice(0, 10) === dateString;
      },
      generateSummary() {
        this.notes = this.originalnotes;
        if (this.employee.howtopay != null && !this.employee.howtopay.toLowerCase().includes('wire')) {
          let added = false;
          if (this.notes == null) this.notes = [];
          else {
            this.notes.forEach((value, index) => {
              // update region on the notes
              if (value.toUpperCase().includes('REGION:')) {
                this.notes[index] = 'REGION:'.concat(this.region ?? '');
                added = true;
              }
            });
          }
          if (added === false) {
            // adding REGION to the end of notes
            this.notes.push('REGION:'.concat(this.region ?? ''));
          }
        } else if (this.employee.howtopay != null && this.employee.howtopay.toLowerCase().includes('wire')) {
          // summary bank payment info
          this.notes = [];
          this.notes.push('IBAN:'.concat(this.iban ?? ''));
          this.notes.push('SWIFT:'.concat(this.swift ?? ''));
          this.notes.push('BANKNAME:'.concat(this.bankname ?? ''));
          if (this.region === 'Brazil') {
            this.notes.push('BANKCODE:'.concat(this.bankcode ?? ''));
            this.notes.push('BRANCHCODE:'.concat(this.branchcode ?? ''));
            this.notes.push('NATIONALTAXID:'.concat(this.nationaltaxid ?? ''));
          }
          this.notes.push('BIRTHDATE:'.concat(this.birthday ?? ''));
          this.notes.push('ADDRESSLINE1:'.concat(this.addressline1 ?? ''));
          this.notes.push('CITY:'.concat(this.city ?? ''));
          this.notes.push('COUNTRY:'.concat(this.country ?? ''));
          this.notes.push('REGION:'.concat(this.region ?? ''));
          this.notes.push('NOTE:'.concat(this.note ?? ''));
        }
        this.employee.notes = this.notes.join('\n');
      },
      changeForm(event) {
        this.errormsg = null;
        this.successmsg = null;
      },
      notificationError(_messsage) {
        this.errormsg = _messsage;
      },
      notificationSusscess(_messsage) {
        this.successmsg = _messsage;
      },
    },
  });
</script>

<style scoped>
  tbody.tasks tr:hover{
    background-color: #f5f5f5;
  }
  a.load-employee-details {
    color: #1a0dab;
  }
</style>
