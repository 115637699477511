<template>
  <mdb-container fluid>
    <Header></Header>
    <mdb-row v-if='error' class='justify-content-around pt-4'>
      <mdb-col md='8'>
        <mdb-alert color='danger' >{{error}}</mdb-alert>
      </mdb-col>
    </mdb-row>
    <Admintopbar :breadcrumb='breadcrumb' />
    <section class="mt-3">
      <mdb-row>
        <mdb-col sm="12">
          <h3>Search Tasks</h3>
          <form onsubmit="(event) => event.preventDefault()" @change="() => getAllTaskqueuesByParams()">
            <mdb-row>
              <mdb-col>
                <mdb-input v-model="from" type="date" label="From" /><br />
                <mdb-input class="mt-3" v-model="to" type="date" label="To" />
              </mdb-col>
              <mdb-col>
                <div>
                  <h4>
                    Customer:
                  </h4>
                  <select
                    name="customer_duplicate"
                    id="customer_duplicate"
                    v-model="userid"
                    class="browser-default custom-select"
                    @change="() => selectCustomer()"
                  >
                    <option value=""></option>
                    <option
                      v-for="(
                        customerItem, index
                      ) in customers"
                      :key="index"
                      :value="customerItem.id"
                    >
                      {{ customerItem.email }}
                    </option>
                  </select>
                </div><br />
                <div>
                  <h4>
                    Project:
                  </h4>
                  <select
                    name="project_duplicate"
                    id="project_duplicate"
                    v-model="projectid"
                    class="browser-default custom-select"
                    @change="() => selectProject()"
                  >
                    <option value=""></option>
                    <option
                      v-for="(
                        projectItem, index
                      ) in projectList"
                      :key="index"
                      :value="projectItem.projectid"
                    >
                      {{ projectItem.name }} - {{ projectItem.client.email }}
                    </option>
                  </select>
                </div>
              </mdb-col>
            </mdb-row>
          </form>
        </mdb-col>
      </mdb-row>
      <mdb-row>
        <mdb-col md="12">
          <mdb-card>
            <mdb-card-header color="mdb-color darken-1">Recent Task Queues</mdb-card-header>
            <mdb-card-body>
              <mdb-row>
                <mdb-col>
                </mdb-col>
                <mdb-col style="text-align: end;">
                  <mdb-btn color="success" @click="$router.push({ name: 'NewTaskQueue' })">Add task queue</mdb-btn>
                </mdb-col>
              </mdb-row>
              <mdb-row>
                <mdb-col>
                  <mdb-tbl hover>
                    <mdb-tbl-head>
                      <tr>
                        <th>ID</th>
                        <th style="width: 30%;">Task Queue Name</th>
                        <th>Project Name</th>
                        <th>Total billing</th>
                        <th>Stripe invoice</th>
                        <th>All tasks completed</th>
                      </tr>
                    </mdb-tbl-head>
                    <mdb-tbl-body v-if="taskqueues != null && taskqueues.length > 0" style="cursor: pointer;">
                      <tr
                        v-for="(taskqueue, index) in taskqueues" :key="index"
                        @click="navigateToDetails(taskqueue.taskqueueid)">
                        <td>{{taskqueue.taskqueueid}}</td>
                        <td>{{taskqueue.queuename}}</td>
                        <td>{{taskqueue.project.name}}</td>
                        <td>{{taskqueue.billingunitquantity}}</td>
                        <td>{{taskqueue.stripeinvoice}}</td>
                        <td>
                          <mdb-icon v-if="taskqueue.iscompleted" icon="check-circle" />
                          <mdb-icon v-else icon="times-circle" />
                        </td>
                      </tr>
                    </mdb-tbl-body>
                    <mdb-tbl-body v-else>
                      <tr>
                        <td colspan="8" style="text-align: center;">No tasks found</td>
                      </tr>
                    </mdb-tbl-body>
                  </mdb-tbl>
                </mdb-col>
              </mdb-row>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
      </mdb-row>
    </section>
  </mdb-container>
</template>

<script>
import Header from '@/components/Header.vue';
import Admintopbar from '@/components/Admintopbar.vue';
import {
    mdbBtn,
    mdbCard,
    mdbCardHeader,
    mdbCol,
    mdbContainer,
    mdbInput,
    mdbRow,
    mdbTbl,
    mdbTblBody,
    mdbTblHead,
    mdbCardBody,
    mdbIcon,
} from 'mdbvue';
import Apicall from '@/libs/Apicall';
import moment from 'moment';

export default {
  components: {
    Admintopbar,
    Header,
    mdbBtn,
    mdbCard,
    mdbCardHeader,
    mdbCol,
    mdbContainer,
    mdbInput,
    mdbRow,
    mdbTbl,
    mdbTblBody,
    mdbTblHead,
    mdbIcon,
    mdbCardBody,
  },

  data() {
    return {
      taskqueues: null,
      error: null,
      breadcrumb: [
        {
          title: 'Taskqueues',
          href: '/admin/tasks',
        },
        {
          title: 'List',
          href: null,
        },
      ],
      from: '',
      to: '',
      userid: null,
      projectid: null,
      projectList: [],
      customers: [],
    };
  },
  mounted() {
    this.to = moment().format('Y-MM-DD');
    this.from = moment().subtract(1, 'months').format('Y-MM-DD');
    this.getAllCustomers();
    this.getAllTaskqueuesByParams();
  },
  methods: {
    getAllTaskqueues() {
      const apiCall = new Apicall();

      apiCall.call('GET', 'api/task/all-task-queues', null)
        .then((response) => {
          if (response.status === 'OK') {
            this.taskqueues = response.data.taskqueueinfos;
          }
        });
    },

    getAllTaskqueuesByParams() {
      const apiCall = new Apicall();

      const body = {
        from: this.from,
        to: this.to,
        projectId: this.projectid,
      };

      this.taskqueues = null;
      apiCall.call('POST', 'api/task/get-all-task-queues-project-by-date-and-proyect-id', body)
        .then((response) => {
          if (response.status === 'OK') {
            this.taskqueues = response.data.taskqueueinfos;
          }
        });
    },

    getAllProjectsByCustomerId() {
      const apiCall = new Apicall();

      apiCall.call('GET', `api/project/get-all-projects-by-customer-id?customerid=${this.userid}`).then((response) => {
        this.projectList = [];
        if (response.status === 'OK') {
          this.projectList = response.data.projectinfos;
        } else {
          this.$emit('error', response.msg);
        }
      });
    },

    getAllCustomers() {
      const apiCall = new Apicall();

      apiCall.call('GET', 'api/admincustomer/list', null).then((response) => {
        if (response.status === 'OK') {
          this.customers = response.data;
        } else {
          this.$emit('error', response.msg);
        }
      });
    },
    navigateToDetails(taskqueueId) {
      const resolvedRoute = this.$router.resolve({ name: 'EditTaskQueue', params: { taskqueueid: taskqueueId } });
      window.open(resolvedRoute.href, '_blank');
    },
    selectProject() {
      if (this.projectid == null || this.projectid === '') {
        this.projectid = null;
        this.userid = null;
      }
    },

    selectCustomer() {
      this.getAllProjectsByCustomerId();
    },
  },
};
</script>

<style>

</style>
