<template>
  <mdb-container fluid>
    <Header></Header>
    <Admintopbar :breadcrumb='breadcrumb' />
    <section class="mt-3">
      <mdb-row>
        <mdb-col md="12">
          <edit-task-queue-form :submit-error="error" :taskqueueId="taskqueueId" :reload="reload" @reload="reloadPage" @submit="submit" :submitting="submitting" />
        </mdb-col>
      </mdb-row>
    </section>
  </mdb-container>
</template>

<script>
import Header from '@/components/Header.vue';
import Admintopbar from '@/components/Admintopbar.vue';
import {
    mdbCol,
    mdbContainer,
    mdbRow,
} from 'mdbvue';
import EditTaskQueueForm from '@/components/EditTaskQueueForm.vue';
import Apicall from '@/libs/Apicall';

export default {
  components: {
    Admintopbar,
    Header,
    mdbCol,
    mdbContainer,
    mdbRow,
    EditTaskQueueForm,
  },

  data() {
    return {
      taskqueueId: null,
      taskqueue: null,
      submitting: false,
      reload: false,
      error: null,
      breadcrumb: [
        {
          title: 'Taskqueues',
          href: '/admin/tasks',
        },
        {
          title: 'Edit',
          href: null,
        },
      ],
    };
  },

  mounted() {
    this.taskqueueId = this.$route.params.taskqueueid;
  },

  watch: {
    error(newVal) {
      if (newVal !== null) {
        window.scrollTo(0, 0);
      }
    },
  },

  methods: {
    submit(taskqueueinfo, taskinfos) {
      const apiCall = new Apicall();

      this.submitting = true;

      apiCall.call('POST', 'api/task/edit-queue', {
        ...taskqueueinfo,
        taskinfos,
      }).then((response) => {
        this.submitting = false;

        if (response.status === 'OK') {
          this.reload = !this.reload;
          this.error = null;
        } else {
          this.error = response.msg;
        }
      });
    },

    reloadPage(taskqueueId) {
      this.$router.push({
        name: 'EditTaskQueue',
        params: { taskqueueid: taskqueueId },
      });
      this.taskqueueId = taskqueueId;
      this.reload = !this.reload;
    },
  },
};
</script>

<style>
</style>
