<template>
    <mdb-container fluid>
        <Header></Header>
        <Admintopbar :breadcrumb='breadcrumb' />
        <section class="mt-3">
            <mdb-row>
                <mdb-col md="12">
                    <task-queue-form :reload="reload" :error-prop="error" @submit="submit" :submitting="submitting" />
                </mdb-col>
            </mdb-row>
        </section>
    </mdb-container>
</template>

<script>
import Header from '@/components/Header.vue';
import Admintopbar from '@/components/Admintopbar.vue';
import {
    mdbCol,
    mdbContainer,
    mdbRow,
} from 'mdbvue';
import TaskQueueForm from '@/components/TaskQueueForm.vue';
import Apicall from '@/libs/Apicall';

export default {
    components: {
        Admintopbar,
        Header,
        mdbCol,
        mdbContainer,
        mdbRow,
        TaskQueueForm,
    },

    data() {
        return {
            taskqueueId: null,
            taskqueue: null,
            submitting: false,
            reload: false,
            error: null,
            breadcrumb: [
                {
                title: 'Taskqueues',
                href: '/admin/tasks',
                },
                {
                title: 'New',
                href: null,
                },
            ],
        };
    },

    mounted() {
      this.taskqueueId = this.$route.params.taskqueueid;
    },

    methods: {
    submit(taskqueueinfo, taskinfos) {
      const apiCall = new Apicall();

      this.submitting = true;

      this.error = null;

      apiCall.call('POST', 'api/task/create-queue', {
        ...taskqueueinfo,
        taskinfos,
      }).then((response) => {
        this.submitting = false;

        if (response.status === 'OK') {
            this.$router.push({
                name: 'Task',
                params: { taskqueueid: response.data.taskqueueinfos.taskqueueid },
            });
            this.taskqueueId = response.data.taskqueueinfos.taskqueueid;
            this.reload = !this.reload;
        } else {
          this.error = response.msg;
        }
      });
    },
  },
};
</script>

<style>

</style>
