import { render, staticRenderFns } from "./InstructionsBox.vue?vue&type=template&id=048e9de7&scoped=true&"
import script from "./InstructionsBox.vue?vue&type=script&lang=js&"
export * from "./InstructionsBox.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "048e9de7",
  null
  
)

export default component.exports