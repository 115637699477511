<template>
  <mdb-container fluid>
    <Header></Header>
    <Admintopbar :breadcrumb='breadcrumb' />
    <section class="mt-3">
      <mdb-card>
        <mdb-card-header class="mdb-color darken-1">
          Mailing campaigns
        </mdb-card-header>
        <mdb-card-body>
          <mdb-row>
            <mdb-col>
              <mdb-btn @click="() => $router.push({ name: 'MailingCampaignForm' })">New</mdb-btn>
            </mdb-col>
          </mdb-row>
          <mdb-row>
            <mdb-tbl v-if="mailingCampaigns !== null" responsive hover>
              <mdb-tbl-head>
                <tr>
                  <th style="font-weight: bold">ID</th>
                  <th style="font-weight: bold">Ident</th>
                  <th style="font-weight: bold">Subject</th>
                </tr>
              </mdb-tbl-head>
              <mdb-tbl-body style="cursor: pointer;">
                <tr
                  v-for="(mailingCampaign, i) in mailingCampaigns"
                  :key="i"
                  @click="() => $router.push({ name: 'MailingCampaignForm', query: { mailingCampaignId: mailingCampaign.id } })"
                >
                  <td>{{mailingCampaign.id}}</td>
                  <td>{{mailingCampaign.ident}}</td>
                  <td>{{mailingCampaign.subject}}</td>
                </tr>
              </mdb-tbl-body>
            </mdb-tbl>
            <mdb-spinner class="text-center" success v-else></mdb-spinner>
          </mdb-row>
        </mdb-card-body>
      </mdb-card>
    </section>
  </mdb-container>
</template>

<script>
import Apicall from '@/libs/Apicall';
import Header from '@/components/Header.vue';
import Admintopbar from '@/components/Admintopbar.vue';
import {
  mdbBtn,
  mdbCard,
  mdbCardHeader,
  mdbCardBody,
  mdbCol,
  mdbContainer,
  mdbRow,
  mdbSpinner,
  mdbTbl,
  mdbTblBody,
  mdbTblHead,
} from 'mdbvue';

export default {
  components: {
    Admintopbar,
    Header,
    mdbBtn,
    mdbCard,
    mdbCardHeader,
    mdbCardBody,
    mdbCol,
    mdbContainer,
    mdbRow,
    mdbSpinner,
    mdbTbl,
    mdbTblBody,
    mdbTblHead,
  },
  data() {
    return {
      mailingCampaigns: null,
      breadcrumb: [
        {
          title: 'Mailing Campaigns',
          href: null,
        },
      ],
    };
  },
  methods: {
    getMailingCampaigns() {
      const apiCall = new Apicall();

      apiCall.call('GET', 'api/mailing-campaign/all-campaigns').then((response) => {
        if (response.status === 'OK') {
          this.mailingCampaigns = response.data.mailingCampaigns;
        } else {
          this.$notify.error({
            message: response.msg,
            position: 'top right',
            timeOut: 5000,
          });
        }
      });
    },
  },
  mounted() {
    this.getMailingCampaigns();
  },
};
</script>

<style>

</style>
